// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ripple from 'vuetify/lib/directives/ripple'

Vue.use(Vuetify, { directives: { ripple } })

const theme = {
  primary: '#3e5565',
  secondary: '#013D4B',
  accent: '#e91e63',
  info: '#00cae3',
  success: '#255538',
  warning: '#cc8800',
  error: '#750015',

  black: '#363636',
  grey: '#969696',
  white: '#f5f5f5',

  transmitted: '#969696',
  processed: '#306D46',
  canceled: '#8C001E',
  fulfilled: '#013D4B',
  fulfilledWithOther: '#00cae3',
  wip: '#E59200',
  ins: '#00cae3',
  'pending-auth': '#8C001E', 
  arancione: '#FFA500',
  
  sparrow_grey: '#969696',
  primary_accent: '#013D4B',
  success_accent: '#306D46',
  error_accent: '#8C001E',
  warning_accent: '#E59200'
}

export default new Vuetify({
  breakpoint: { mobileBreakpoint: 960 },
  icons: {
    values: { expand: 'mdi-menu-down' },
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
