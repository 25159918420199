// Pathify
import {make} from 'vuex-pathify';
import utils from './utils';
import moment from 'moment';
import tenantConfiguration from './tenantConfiguration';

// const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const OrderDraftApi = new MyfamilysrlNikitaSdk.OrderDraftApi();
const OrderDraftRowApi = new MyfamilysrlNikitaSdk.OrderDraftRowApi();
const CustomersApi = new MyfamilysrlNikitaSdk.CustomersApi();
const OrderTypeApi = new MyfamilysrlNikitaSdk.OrderTypeApi();
const VatPercentageApi = new MyfamilysrlNikitaSdk.VatPercentageApi();
const PaymentTermApi = new MyfamilysrlNikitaSdk.PaymentTermApi();
const PaymentMethodApi = new MyfamilysrlNikitaSdk.PaymentMethodApi();
const OrderApi = new MyfamilysrlNikitaSdk.OrderApi();
const HistoricalOrderApi = new MyfamilysrlNikitaSdk.HistoricalOrderApi();
const OrderRowApi = new MyfamilysrlNikitaSdk.OrderRowApi();
const OrderStatusApi = new MyfamilysrlNikitaSdk.OrderStatusApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

// Data
const state = {
  inExecutionSaveOrderDraftRows: false,
  imSuperUserPremiumPlus: false,
  orderRows: [],
  orderStatus: [],
  customersForNewOrder: [],
  orderTypes: [],
  paymentTerms: [],
  paymentMethods: [],
  paymentTermDeadlines: [],
  ordersTotal: 0.0,
  orderRowFilter: { itemCodes: [], isGift: null, isLfu: null, qtyMin: null, qtyMax: null, itemCollections: [], itemGroups: [], itemFamilies: [], discountPercentageMin: null, discountPercentageMax: null, documentCurOriginalPriceMin: null, documentCurOriginalPriceMax: null },
  orderDraft: { agentId: 1, cashOnDeliveryDiscount: null, chiefAgentId: null, code: null, documentCurCollectionCharges: null, commercialSpecialistId: null, documentCurContextualDeposit: null, customerId: null, customerLocationId: null, customerPurchaseOrder: null, deleted: null, documentCurDeliveryCharges: null, draftPhase: null, estimatedDeliveryDate: null, familyId: null, notes: null, offerExpirationDate: null, orderDate: null, documentCurOrderDiscountAbsolute: null, orderDiscountPercentage: null, orderTypeId: null, paymentTermId: null, priceCatalogId: null, privateNotes: null, rowsAmountsBeforeDiscount: "0.00", rowsDiscountAmount: "0.00", documentCurRowsTotal: 0.00, documentCurSubtotal: null, supervisorAgentId: null, documentCurTaxable: null, documentCurTaxes: null, total: null, updateTimestamp: null, updateUser: null, vatPercentageId: null, hasDifferentInvoicingCustomer: false, hasDifferentShippingCustomer: false },
  orders: [],
  orderRowContent: [],
  filters: { code: null, orderDate: `0101${moment().year()}..${moment().format('DDMMYYYY')}`, orderTypeId: [], customerId: [], customerLocationId: [], commercialSpecialistId: [], agentId: [], supervisorAgentId: [], chiefAgentId: [], customerPurchaseOrder: null, offerExpirationDate: null, estimatedDeliveryDate: null, paymentTermId: [], documentCurSubtotal: null, collectionCharges: null, deliveryCharges: null, documentCurRowsTotal: null, taxable: null, vatPercentageId: [], taxes: null, cashOnDeliveryDiscount: null, orderDiscountPercentage: null, documentCurOrderDiscountAbsolute: null, total: null, contextualDeposit: null, orderStatus: null, notes: null, privateNotes: null, familyId: [], priceCatalogId: [], hasMandatoryShippingDate: null, zoneId: [], customerBusinessTypeId: [], alsoHistorical: null, hasEngraver: null },
  showFilters: false
}

// setTimeout(() => {
//   let temp = localStorage.getItem("NikitaOrderFilters") ?? "{}";
//   if (!!temp) {
//     let storedFilters = JSON.parse(temp ?? "{}") ?? {};
//     Object.keys(storedFilters).forEach(k => {
//       state.filters[k] = storedFilters[k];
//     })
//     if(Object.keys(storedFilters).filter(x => x != 'orderDate' && !!storedFilters[x] && !!storedFilters[x].length).length){
//       state.showFilters = true;
//     }
//   }
// }, 500);

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),

  initOrderCollection: async ({dispatch}, force) => {
    if (state.orders.length === 0 || !!force) {
      // dispatch('apiOrderGet', force);
      dispatch('applyFilters', state.filters);
    }
  },

  initOrderRowContentCollection: async ({dispatch}, force) => {
    if (state.orders.length === 0 || !!force) {
      dispatch('apiOrderRowContentGet', force);
    }
  },

  initOrderDraftCollection: async ({dispatch}, force) => {
    if (!state.orderDraft || !!force) {
      dispatch('apiOrderDraftGet');
    }
  },

  initOrderTypesCollection: async ({dispatch}, force) => {
    if (state.orderTypes.length === 0 || !!force) {
      dispatch('orderTypesGet');
    }
  },

  initPaymentTermCollection: async ({dispatch}, force) => {
    if (state.paymentTerms.length === 0 || !!force) {
      dispatch('paymentTermsGet');
    }
  },

  initPaymentMethodCollection: async ({dispatch}, force) => {
    if (state.paymentMethods.length === 0 || !!force) {
      dispatch('paymentMethodsGet');
    }
  },

  initOrderStatusCollection: async ({dispatch}, force) => {
    if (state.orderStatus.length === 0 || !!force) {
      dispatch('apiOrderStatusGet');
    }
  },

  initOrderRowContentFilters: async ({dispatch}) => {
    state.filters = { code: null, orderDate: `0101${moment().year()}..${moment().format('DDMMYYYY')}`, orderTypeId: [], customerId: [], customerLocationId: [], commercialSpecialistId: [], agentId: [], supervisorAgentId: [], chiefAgentId: [], customerPurchaseOrder: null, offerExpirationDate: null, estimatedDeliveryDate: null, paymentTermId: [], documentCurSubtotal: null, collectionCharges: null, deliveryCharges: null, documentCurRowsTotal: null, taxable: null, vatPercentageId: [], taxes: null, cashOnDeliveryDiscount: null, orderDiscountPercentage: null, documentCurOrderDiscountAbsolute: null, total: null, contextualDeposit: null, orderStatus: null, notes: null, privateNotes: null, familyId: [], priceCatalogId: [], hasMandatoryShippingDate: null, zoneId: [], customerBusinessTypeId: [], alsoHistorical: null, hasEngraver: null };
    state.orderRowFilter = { itemCodes: [],isGift: null,isLfu: null,qtyMin: null,qtyMax: null,itemCollections: [],itemGroups: [],itemFamilies: [],discountPercentageMin: null,discountPercentageMax: null,documentCurOriginalPriceMin: null,documentCurOriginalPriceMax: null };
  },

  apiOrderIdOrderRowsPatch: async ({dispatch}, obj = {
    orderId: "",
    collection: []
  }) => new Promise((resolve, reject) => {
    utils.actions.loadRes();
    let opts = {
      'withoutRecalculation': false,
      'protoOrderRow': [],
      MF_TENANT_ID
    }
    obj.collection.forEach((el) => {
      if (!!el.itemCode && el.itemCode !== '' && !!el.quantity) {
        el.orderId = obj.orderId;
        opts.protoOrderRow.push(MyfamilysrlNikitaSdk.ProtoOrderRow.constructFromObject(el));
      }
    });
    OrderApi.apiOrderIdOrderRowsPatch(obj.orderId, opts).then(() => {
      dispatch("apiOrderIdOrderRowsGet", obj.orderId).then((result) => {
        dispatch("setOrderRows", result);
        dispatch("apiOrderIdOrderRowsGet", obj.orderId);
        resolve(result);
      });
    }).catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      reject(error);
    })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  apiOrderStatusGet: async ({dispatch}) => new Promise((resolve, reject) => {
    utils.actions.loadRes();
    OrderStatusApi.apiOrderStatusGet({MF_TENANT_ID})
        .then((result) => {
          dispatch("setOrderStatus", result);
          resolve(result);
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          reject(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  apiOrderIdOrderRowsGet: async ({dispatch}, orderId) => new Promise((resolve, reject) => {
    utils.actions.loadRes();
    OrderApi.apiOrderIdOrderRowsGet(orderId, {MF_TENANT_ID})
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          reject(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  apiOrderIdGet: async ({dispatch}, orderId) => new Promise((resolve, reject) => {
    utils.actions.loadRes();
    OrderApi.apiOrderIdGet(orderId, {MF_TENANT_ID})
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          reject(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  apiHistoricalOrderIdGet: async ({dispatch}, orderId) => new Promise((resolve, reject) => {
    utils.actions.loadRes();
    HistoricalOrderApi.apiHistoricalOrderIdGet(orderId, {MF_TENANT_ID})
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          reject(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  apiHistoricalOrderIdOrderRowsGet: async ({dispatch}, orderId) => new Promise((resolve, reject) => {
    utils.actions.loadRes();
    HistoricalOrderApi.apiHistoricalOrderIdOrderRowsGet(orderId, {MF_TENANT_ID})
    .then((result) => {
      resolve(result);
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      reject(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  saveOrderDraft: async ({dispatch}) => new Promise((res, rej) => {
    utils.actions.loadRes();
    if (!!state.orderDraft.orderDate) {
      state.orderDraft.orderDate = new Date(state.orderDraft.orderDate.toISOString().substr(0, 10));
    }
    if (!!state.orderDraft.estimatedDeliveryDate) {
      state.orderDraft.estimatedDeliveryDate = new Date(state.orderDraft.estimatedDeliveryDate.toISOString().substr(0, 10))
    }
    let opts = {
      'orderDraft': MyfamilysrlNikitaSdk.OrderDraft.constructFromObject(state.orderDraft),
      MF_TENANT_ID
    };
    if (state.orderDraft.id) {
      let stringId = `${state.orderDraft.id}`;
      OrderDraftApi.apiOrderDraftIdPut(stringId, opts)
          .then((result) => {
            dispatch('setOrderDraft', result);
            dispatch('calculateDeadlines');
            res(result);
          })
          .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
          })
          .finally(() => {
            utils.actions.unloadRes();
          });
    } else {
      OrderDraftApi.apiOrderDraftPost(opts)
          .then((result) => {
            dispatch('setOrderDraft', result);
            res(result);
          })
          .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
          })
          .finally(() => {
            utils.actions.unloadRes();
          });
    }
  }),

  apiOrderGet: async ({dispatch}, force) => new Promise((res, rej) => {
    !!force && utils.actions.loadRes();
    OrderApi.apiOrderGet({MF_TENANT_ID})
        .then((result) => {
          if (!!result && result.orders.length > 0) {
            result.orders.sort((a, b) => {
              return +RegExp(/\d+$/).exec(b.code) - +RegExp(/\d+$/).exec(a.code);
            });
            dispatch('setOrders', result.orders);
            dispatch('setOrdersTotal', result[tenantConfiguration.state.prefixValueSelector + "Total"]);
          }
          res(result);
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        })
        .finally(() => {
          !!force && utils.actions.unloadRes();
        });
  }),

  apiOrderRowContentGet: async ({dispatch}, force) => new Promise((res, rej) => {
    !!force && utils.actions.loadRes();
    let opts = {
      orderRowFilters: {
        ...MyfamilysrlNikitaSdk.OrderRowFilters.constructFromObject(state.orderRowFilter),
        orderFilters: MyfamilysrlNikitaSdk.OrderFilters.constructFromObject(state.filters)
      },
      MF_TENANT_ID
    }
    OrderRowApi.apiOrderRowFilteredPost(opts)
        .then((result) => {
          dispatch('setOrderRowContent', result);
          res(result);
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          console.error(error);
          rej(error);
        })
        .finally(() => {
          !!force && utils.actions.unloadRes();
        });
  }),

  apiOrderDraftGet: async ({dispatch}) => new Promise((res, rej) => {
    utils.actions.loadRes();
    OrderDraftApi.apiOrderDraftGet({MF_TENANT_ID})
        .then((result) => {
          if (!!result && result.length > 0) {
            dispatch('setOrderDraft', result[0]);
          }
          res(result);
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  apiOrderDraftPost: async ({dispatch}, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      'orderDraft': MyfamilysrlNikitaSdk.OrderDraft.constructFromObject(payload),
      MF_TENANT_ID
    };
    OrderDraftApi.apiOrderDraftPost(opts)
        .then(() => {
          dispatch('apiOrderDraftGet');
          dispatch('app/showSuccess', 'completed', {root: true});
          res();
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej();
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  apiOrderDraftIdPut: async ({dispatch}, payload) => new Promise((res, rej) => {
    utils.actions.loadRes()
    let opts = {
      'orderDraft': MyfamilysrlNikitaSdk.OrderDraft.constructFromObject(payload),
      MF_TENANT_ID
    };
    let stringId = `${payload.id}`;
    OrderDraftApi.apiOrderDraftIdPut(stringId, opts)
        .then(() => {
          dispatch('apiOrderDraftGet');
          res();
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  apiOrderDraftIdDelete: async ({dispatch}, id) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let stringId = `${id}`;
    OrderDraftApi.apiOrderDraftIdDelete(stringId, {MF_TENANT_ID})
        .then(() => {
          dispatch("setOrderDraft", {
            agentId:1,
            cashOnDeliveryDiscount: null,
            chiefAgentId: null,
            code: null,
            documentCurCollectionCharges: null,
            commercialSpecialistId: null,
            documentCurContextualDeposit: null,
            customerId: null,
            customerLocationId: null,
            customerPurchaseOrder: null,
            deleted: null,
            documentCurDeliveryCharges: null,
            draftPhase: null,
            estimatedDeliveryDate: null,
            familyId: null,
            notes: null,
            offerExpirationDate: null,
            orderDate: null,
            documentCurOrderDiscountAbsolute: null,
            orderDiscountPercentage: null,
            orderTypeId: null,
            paymentTermId: null,
            priceCatalogId: null,
            privateNotes: null,
            rowsAmountsBeforeDiscount: "0.00",
            rowsDiscountAmount: "0.00",
            documentCurRowsTotal: 0.00,
            documentCurSubtotal: null,
            supervisorAgentId: null,
            documentCurTaxable: null,
            documentCurTaxes: null,
            total: null,
            updateTimestamp: null,
            updateUser: null,
            vatPercentageId: null,
            hasDifferentInvoicingCustomer: false,
            hasDifferentShippingCustomer: false
          })
          dispatch("setPaymentTermDeadlines", []);
          dispatch("setOrderDraftId", null);
          res();
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  orderDraftRowsGet: async ({dispatch}) => new Promise((res, rej) => {
    utils.actions.loadRes();
    if (!!state.orderDraft && !!state.orderDraft.id) {
      OrderDraftApi.apiOrderDraftIdOrderDraftRowsGet(state.orderDraft.id, {MF_TENANT_ID})
          .then((result) => {
            if (!!result && !!result.length) {
              dispatch("setOrderRows", result);
              res(result);
            } else {
              dispatch("setOrderRows", [{
                rowNumber: 10,
                orderDraftId: state.orderDraft.id,
                itemCode: '',
                itemDescription: '',
                quantity: 1,
                documentCurOriginalPrice: null,
                discountPercentage: null,
                isGift: false,
                isLoanForUse: false,
                notes: ''
              }]);
              res(result);
            }
          })
          .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
          })
          .finally(() => {
            utils.actions.unloadRes();
          });
    } else {
      dispatch("setOrderRows", [{
        rowNumber: 10,
        orderDraftId: 0,
        itemCode: '',
        itemDescription: '',
        quantity: null,
        documentCurOriginalPrice: null,
        discountPercentage: null,
        isGift: false,
        isLoanForUse: false,
        notes: ''
      }]);
      res();
      utils.actions.unloadRes();
    }
  }),

  editOrder: async ({dispatch}, updatedOrder) => new Promise((res, rej) => {
    utils.actions.loadRes();
    if (!!updatedOrder) {
      let opts = {
        'order': MyfamilysrlNikitaSdk.Order.constructFromObject(updatedOrder),
        MF_TENANT_ID
      };
      OrderApi.apiOrderIdPut(updatedOrder.id, opts)
          .then((result) => {
            res(result);
          })
          .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
          })
          .finally(() => {
            utils.actions.unloadRes();
          });
    }
  }),

  getUpdatedOrder: async ({dispatch}, orderId) => new Promise((res, rej) => {
    utils.actions.loadRes();
    OrderApi.apiOrderIdGet(orderId, {MF_TENANT_ID})
        .then((result) => {
          let index = state.orders.findIndex(x => x.id === orderId);
          if (index >= 0) {
            let newOrders = state.orders.splice();
            newOrders[index] = result;
            dispatch("setOrders", newOrders);
          }
          res(result);
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  // inExecutionSaveOrderDraftRows
  saveOrderDraftRows: async ({dispatch}, collection = null) => new Promise(async(res, rej) => {
    utils.actions.loadRes();
    // if(!!state.inExecutionSaveOrderDraftRows) {
    //   await new Promise(resolve => setTimeout(resolve, 1000))
    //   return actions.saveOrderDraftRows(collection);
    // } else {
    //   state.inExecutionSaveOrderDraftRows = true;
      let opts = {
        'orderDraftRow': [],
        MF_TENANT_ID
      };
      (collection || state.orderRows).forEach((el) => {
        if (!!el.itemCode && el.itemCode !== '') {
          el.orderDraftId = state.orderDraft.id;
          opts.orderDraftRow.push(MyfamilysrlNikitaSdk.OrderDraftRow.constructFromObject(el));
        }
      })
      OrderDraftApi.apiOrderDraftIdOrderDraftRowsPatch(state.orderDraft.id, opts)
      .then(() => {
        dispatch("orderDraftRowsGet");
        dispatch("apiOrderDraftGet");
        res();
      })
      .catch((error) => {
        dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
        rej(error);
      })
      .finally(() => {
        utils.actions.unloadRes();
      });
    // }
  }),

  orderTypesGet: async ({dispatch}) => new Promise((res, rej) => {
    utils.actions.loadRes();
    OrderTypeApi.apiOrderTypeGet({MF_TENANT_ID})
        .then((result) => {
          dispatch("setOrderTypes", result);
          res(result);
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  paymentTermsGet: async ({dispatch}) => new Promise((res, rej) => {
    utils.actions.loadRes();
    PaymentTermApi.apiPaymentTermGet({MF_TENANT_ID})
        .then((result) => {
          dispatch("setPaymentTerms", result);
          res(result);
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

   // (paymentTerm)
  apiPaymentTermPost: async ({dispatch}, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {'paymentTerm': MyfamilysrlNikitaSdk.PaymentTerm.constructFromObject({...payload, tenantUuid: MF_TENANT_ID}), MF_TENANT_ID};
    PaymentTermApi.apiPaymentTermPost(opts)
    .then((response) => {
      dispatch("initPaymentTermCollection", true);
      res(response);
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    })
  }),
  // (id, paymentTerm)
  apiPaymentTermIdPut: async ({dispatch}, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    PaymentTermApi.apiPaymentTermIdPut(payload.id, { paymentTerm : payload, MF_TENANT_ID})
    .then((response) => {
      dispatch("initPaymentTermCollection", true);
      res(response);
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    })
  }),

  paymentMethodsGet: async ({dispatch}) => new Promise((res, rej) => {
    utils.actions.loadRes();
    PaymentMethodApi.apiPaymentMethodGet({MF_TENANT_ID})
        .then((result) => {
          dispatch("setPaymentMethods", result);
          res(result);
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  // (paymentMethod)
  apiPaymentMethodPost: async ({dispatch}, payload) => new Promise((res, rej) => {
   utils.actions.loadRes();
   let opts = {'paymentMethod': MyfamilysrlNikitaSdk.PaymentMethod.constructFromObject({...payload, tenantUuid: MF_TENANT_ID}), MF_TENANT_ID};
   PaymentMethodApi.apiPaymentMethodPost(opts)
   .then((response) => {
     dispatch("initPaymentMethodCollection", true);
     res(response);
   })
   .catch((error) => {
     dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
     rej(error);
   })
   .finally(() => {
     utils.actions.unloadRes();
   })
 }),
 // (id, paymentMethod)
 apiPaymentMethodIdPut: async ({dispatch}, payload) => new Promise((res, rej) => {
   utils.actions.loadRes();
   PaymentMethodApi.apiPaymentMethodIdPut(payload.id, { paymentMethod : payload, MF_TENANT_ID})
   .then((response) => {
     dispatch("initPaymentMethodCollection", true);
     res(response);
   })
   .catch((error) => {
     dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
     rej(error);
   })
   .finally(() => {
     utils.actions.unloadRes();
   })
 }),

  // Customer for New Order
  getCustomersForNewOrder: async ({dispatch}, filters) => new Promise((res, rej) => {
    utils.actions.loadRes();
    getCustomersFromFilters({...filters, MF_TENANT_ID})
        .then((result) => {
          dispatch('setCustomersForNewOrder', result);
          res(result);
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  calculateDeadlines: async ({dispatch}, orderObj = state.orderDraft) => new Promise((res, rej) => {
    utils.actions.loadRes();
    const paymentTermId = orderObj.paymentTermId;
    const estimatedDeliveryDate = orderObj.estimatedDeliveryDate || new Date().toISOString().substr(0, 10);
    const opts = {
      totalAmount: +orderObj.documentCurTotal,
      MF_TENANT_ID
    }
    if (!!paymentTermId && !!estimatedDeliveryDate && !!opts.totalAmount) {
      PaymentTermApi.apiPaymentTermPaymentTermIdPaymentDeadlinesGet(paymentTermId, estimatedDeliveryDate, opts)
          .then((result) => {
            dispatch('setPaymentTermDeadlines', result);
            res(result);
          })
          .catch((error) => {
            rej(error);
          })
          .finally(() => {
            utils.actions.unloadRes();
          })
    } else {
      rej();
      utils.actions.unloadRes();
    }
  }),

  saveAndReleaseOrderDraft: async ({dispatch}) => new Promise((res, rej) => {
    utils.actions.loadRes();
    dispatch('saveOrderDraft')
        .then(() => {
          //dindu
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        })
        .finally(() => {

          dispatch('releaseOrderDraft', state.orderDraft.id)
              .then(() => {
                dispatch('apiOrderDraftGet');
                dispatch('initOrderCollection', true);
                res();
              })
              .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                rej(error);
              })
              .finally(() => {
                utils.actions.unloadRes();
              });

        });
  }),

  releaseOrderDraft: async ({dispatch}, id) => new Promise((res, rej) => {
    utils.actions.loadRes();
    OrderApi.apiOrderFromDraftOrderIdPost(id, {MF_TENANT_ID})
        .then(() => {
          dispatch("setOrderDraft", { agentId: 1, cashOnDeliveryDiscount: null, chiefAgentId: null, code: null, collectionCharges: null, commercialSpecialistId: null, contextualDeposit: null, customerId: null, customerLocationId: null, customerPurchaseOrder: null, deleted: null, deliveryCharges: null, draftPhase: null, estimatedDeliveryDate: new Date(), familyId: null, notes: null, offerExpirationDate: null, orderDate: new Date(), documentCurOrderDiscountAbsolute: null, orderDiscountPercentage: null, orderTypeId: null, paymentTermId: null, priceCatalogId: null, privateNotes: null, rowsAmountsBeforeDiscount: "0.00", rowsDiscountAmount: "0.00", documentCurRowsTotal: 0.00, documentCurSubtotal: null, supervisorAgentId: null, taxable: null, taxes: null, total: null, updateTimestamp: null, updateUser: null, vatPercentageId: null, hasDifferentInvoicingCustomer: false, hasDifferentShippingCustomer: false });
          dispatch("apiOrderGet");
          res();
        })
        .catch((error) => {
          if (error.status === 400) {
            error = `Some essential data is missing from the Order Draft. Check the followings and try again: Customer, Family, Order Date, Customer Location, Order Type, Payment Term, Price Catalog or VAT Tax Rate. [${error.body}]`
          }
          if (error.status === 422) {
            error = "The owrnership of the draft's cusutoemr are not correctly setted. Please review them, and retry.";
          }
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  applyFilters: async ({dispatch}, payload) => new Promise((res, rej) => {
    // localStorage.setItem("NikitaOrderFilters", JSON.stringify(payload));
    utils.actions.loadRes();
    let opts = {
      orderFilters: MyfamilysrlNikitaSdk.OrderFilters.constructFromObject(payload),
      MF_TENANT_ID
    }
    
    // opts.orderFilters.orderDate = opts.orderFilters.orderDate ?? `0101${moment().year()}..${moment().format('DDMMYYYY')}`;

    OrderApi.apiOrderFilteredPost(opts)
        .then((result) => {
          result.orders.sort((a, b) => {
            return +RegExp(/\d+$/).exec(b.code) - +RegExp(/\d+$/).exec(a.code);
          });
          dispatch('setOrders', result.orders);
          dispatch('setOrdersTotal', result[tenantConfiguration.state.prefixValueSelector + "Total"]);
          dispatch('setFilters', MyfamilysrlNikitaSdk.OrderFilters.constructFromObject(payload));
          res(result);
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  apiOrderCustomerIdGet: async ({dispatch}, payload = []) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      orderFilters: MyfamilysrlNikitaSdk.OrderFilters.constructFromObject({customerId: [...payload]}),
      MF_TENANT_ID
    }
    OrderApi.apiOrderFilteredPost(opts)
        .then((result) => {
          result.orders.sort((a, b) => {
            return +RegExp(/\d+$/).exec(b.code) - +RegExp(/\d+$/).exec(a.code);
          });
          res(result);
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  apiOrderExportMetodoPost: ({dispatch}) => new Promise((res, rej) => {
    utils.actions.loadRes();
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        utils.actions.unloadRes();
        if (xhr.status == 200) {
          dispatch('applyFilters', state.filters);
          res(xhr.response);
        } else {
          const error = `${xhr.status} - ${xhr.statusText}`;
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        }
      }
    };
    xhr.open("POST", `${process.env.VUE_APP_BACKEND_BASEPATH}/api/Order/export/metodo`, true);
    xhr.setRequestHeader('Content-Type', 'application/json'); // 'text/plain'
    xhr.setRequestHeader('Authorization', MyfamilysrlNikitaSdk.ApiClient.instance.defaultHeaders["Authorization"]);
    xhr.setRequestHeader('Accept', 'text/plain');
    xhr.setRequestHeader('Mf-Tenant-Id',MF_TENANT_ID);
    xhr.responseType = 'arraybuffer';
    xhr.send(JSON.stringify(state.filters));
  }),

  apiOrderConfirmation: ({dispatch}, params) => new Promise((res, rej) => {
    utils.actions.loadRes();
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        utils.actions.unloadRes();
        if (xhr.status == 200) {
          res(xhr.response);
        } else {
          const error = `${xhr.status} - ${xhr.statusText}`;
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        }
      }
    };
    xhr.open("GET", `${process.env.VUE_APP_REPORTING_BASEPATH}/api/ConfirmOrders/${params.lang}/${params.orderId}.pdf`, true);
    xhr.setRequestHeader('Content-Type', 'application/pdf'); // 'text/plain'
    xhr.setRequestHeader('Authorization', MyfamilysrlNikitaSdk.ApiClient.instance.defaultHeaders["Authorization"]);
    xhr.setRequestHeader('Accept', 'application/pdf') ;
    xhr.responseType = 'arraybuffer';
    xhr.send();
  }),

  apiOrderIdChangeStatusToPatch: ({dispatch}, opts) => new Promise((res, rej) => {
    utils.actions.loadRes();
    const orderId = opts.orderId || 0;
    delete (opts.orderId);
    OrderApi.apiOrderIdChangeStatusToPatch(orderId, {...opts, MF_TENANT_ID})
        .then(() => {
          // dispatch("getUpdatedOrder", orderId);
          dispatch('applyFilters', state.filters);
          dispatch('app/showSuccess', "Successfully changed order state", {root: true});
          res();
        })
        .catch((error) => {
          if(error.status === 423) {
            let order = state.orders.find(el => el.id == orderId);
            window.location.assign(`/customers/${ order?.invoicingCustomerId ?? order?.customerId }`);
          }
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),
  
  apiOrderIdRecalculateTotalsPost: async ({dispatch}, orderId) => new Promise((resolve, reject) => {
    utils.actions.loadRes();
    OrderApi.apiOrderIdRecalculateTotalsPost(orderId, {MF_TENANT_ID})
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          reject(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  apiOrderIdDuplicatePost: ({dispatch}, id) => new Promise((res, rej) => {
    utils.actions.loadRes();
    OrderApi.apiOrderIdDuplicatePost(id, {MF_TENANT_ID})
        .then((clonedOrder) => {
          !!clonedOrder.notes || !!clonedOrder.privateNotes
            ? dispatch('app/showWarning', `Make sure to check the notes in your order for important information. [${clonedOrder.code}]`, {root: true})
            : dispatch('app/showSuccess', "Successfully changed order state", {root: true});
          res(clonedOrder);
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  apiOrdersContentListExport: ({dispatch}) => new Promise((res, rej) => {
    utils.actions.loadRes();
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        utils.actions.unloadRes();
        if (xhr.status === 200) {
          let params = {
            content:  xhr.response,
            filename: 'OrdersContentListExport' 
          };
          actions.downloadExcel(params);
          res(xhr.response);
        } else {
          const error = `${xhr.status} - ${xhr.statusText}`;
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        }
      }
    };
    xhr.open("POST", `${process.env.VUE_APP_REPORTING_BASEPATH}/api/OrdersContentListExport`, true);
    xhr.setRequestHeader('Content-Type', 'application/json'); // 'text/plain'
    xhr.setRequestHeader('Authorization', MyfamilysrlNikitaSdk.ApiClient.instance.defaultHeaders["Authorization"]);
    xhr.setRequestHeader('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    xhr.responseType = 'arraybuffer';
    xhr.setRequestHeader('Mf-Tenant-Id',MF_TENANT_ID);
    xhr.send(JSON.stringify({
      ...MyfamilysrlNikitaSdk.OrderRowFilters.constructFromObject(state.orderRowFilter),
      orderFilters: MyfamilysrlNikitaSdk.OrderFilters.constructFromObject(state.filters)
    }));
  }),

  apiOrdersListExport: ({dispatch}) => new Promise((res, rej) => {
    utils.actions.loadRes();
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        utils.actions.unloadRes();
        if (xhr.status === 200) {
          res(xhr.response);
        } else {
          const error = `${xhr.status} - ${xhr.statusText}`;
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        }
      }
    };
    xhr.open("POST", `${process.env.VUE_APP_REPORTING_BASEPATH}/api/OrdersListExport`, true);
    //! old api url
    // xhr.open("POST", `${process.env.VUE_APP_BACKEND_BASEPATH}/reporting/OrdersListExport`, true);
    xhr.setRequestHeader('Content-Type', 'application/json'); // 'text/plain'
    xhr.setRequestHeader('Authorization', MyfamilysrlNikitaSdk.ApiClient.instance.defaultHeaders["Authorization"]);
    xhr.setRequestHeader('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    xhr.responseType = 'arraybuffer';
    xhr.setRequestHeader('Mf-Tenant-Id',MF_TENANT_ID);
    xhr.send(JSON.stringify(state.filters));
    //! old version, with IDs
    // xhr.send(JSON.stringify({ orderIds: orderIds }));
  }),

  apiOrderDraftIdApplyTaxesPost: ({dispatch}, orderDraftId) => new Promise((res, rej) => {
    utils.actions.loadRes();
    OrderDraftApi.apiOrderDraftIdApplyTaxesPost(orderDraftId, {MF_TENANT_ID})
    .then((vatPercentage) => {
      dispatch('apiOrderDraftGet');
      dispatch('app/showSuccess', "Successfully applied sales taxes", {root: true});
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  apiOrderIdApplyTaxesPost: ({dispatch}, orderId) => new Promise((res, rej) => {
    utils.actions.loadRes();
    OrderApi.apiOrderIdApplyTaxesPost(orderId, {MF_TENANT_ID})
        .then((vatPercentage) => {
          dispatch('apiOrderGet');
          dispatch('app/showSuccess', "Successfully applied sales taxes", {root: true});
          res(vatPercentage);
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  apiOrderCanIWIPGet: ({dispatch}) => new Promise((res, rej) => {
    utils.actions.loadRes();
    OrderApi.apiOrderCanIWIPGet({MF_TENANT_ID})
        .then((response) => {
          dispatch('setImSuperUserPremiumPlus', response);
          res(response);
        })
        .catch((error) => {
          rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  apiOrderRowTagMachinesByCustomerCustomerIdGet: async ({dispatch}, id) => new Promise((res, rej) => {
    utils.actions.loadRes();
    OrderRowApi.apiOrderRowTagMachinesByCustomerCustomerIdGet(id, {MF_TENANT_ID})
    .then((result) => {
      res(result);
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => { utils.actions.unloadRes() });
  }),

  apiOrderRowDisplayAndMerchandisingByCustomerCustomerIdGet: async ({dispatch}, id) => new Promise((res, rej) => {
    utils.actions.loadRes();
    OrderRowApi.apiOrderRowDisplayAndMerchandisingByCustomerCustomerIdGet(id, {MF_TENANT_ID})
    .then((result) => {
      res(result);
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => { utils.actions.unloadRes() });
  }),

  apiPlanningExport: ({dispatch}) => new Promise((res, rej) => {
    utils.actions.loadRes();
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        utils.actions.unloadRes();
        if (xhr.status === 200) {
          res(xhr.response);
        } else {
          const error = `${xhr.status} - ${xhr.statusText}`;
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        }
      }
    };
    xhr.open("GET", `${process.env.VUE_APP_REPORTING_BASEPATH}/OrdersWithMachines/PriorityOrders`, true);
    xhr.setRequestHeader('Content-Type', 'application/json'); // 'text/plain'
    xhr.setRequestHeader('Authorization', MyfamilysrlNikitaSdk.ApiClient.instance.defaultHeaders["Authorization"]);
    xhr.setRequestHeader('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    xhr.responseType = 'arraybuffer';
    xhr.send();
  }),

  apiPlanningProcessedExport: ({dispatch}) => new Promise((res, rej) => {
    utils.actions.loadRes();
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        utils.actions.unloadRes();
        if (xhr.status === 200) {
          res(xhr.response);
        } else {
          const error = `${xhr.status} - ${xhr.statusText}`;
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        }
      }
    };
    xhr.open("GET", `${process.env.VUE_APP_REPORTING_BASEPATH}/OrdersWithMachines/ProcessedOrders`, true);
    xhr.setRequestHeader('Content-Type', 'application/json'); // 'text/plain'
    xhr.setRequestHeader('Authorization', MyfamilysrlNikitaSdk.ApiClient.instance.defaultHeaders["Authorization"]);
    xhr.setRequestHeader('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    xhr.responseType = 'arraybuffer';
    xhr.send();
  }),

  //! orderDate: data dell'ordine
  apiPlanningExportByAurora: ({dispatch}, orderDate) => new Promise((res, rej) => {
    utils.actions.loadRes();
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        utils.actions.unloadRes();
        if (xhr.status === 200) {
          res(xhr.response);
        } else {
          const error = `${xhr.status} - ${xhr.statusText}`;
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        }
      }
    };
    xhr.open("POST", `${process.env.VUE_APP_REPORTING_BASEPATH}/api/ItemsOrdered/WithQuantities`, true);
    xhr.setRequestHeader('Content-Type', 'application/json'); // 'text/plain'
    xhr.setRequestHeader('Authorization', MyfamilysrlNikitaSdk.ApiClient.instance.defaultHeaders["Authorization"]);
    xhr.setRequestHeader('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    xhr.responseType = 'arraybuffer';
    xhr.send(JSON.stringify({ orderDate, tenantId: localStorage.getItem('tenantUuid')}));
  }),

  apiCutOrderFromOrderRows: ({dispatch}, params) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      bulkOrderRowDto: [...params.map(x => ({...MyfamilysrlNikitaSdk.BulkOrderRowDto.constructFromObject(x), quantity: x.qty}))],
      MF_TENANT_ID
    };
    OrderApi.apiOrderCutOrderFromOrderRowsPost(opts)
    .then((result) => {
      res(result);
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),
  
  downloadExcel: function (params) {
    var binaryData = [];
    binaryData.push(params.content);
    const url = window.URL.createObjectURL(new Blob(binaryData));
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${params.filename}.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  },

}

const getCustomersFromFilters = (filters) => new Promise((res, rej) => {
  let opts = { agentId: +filters.agentId, familyId: +filters.familyId, MF_TENANT_ID };
  CustomersApi.apiCustomersGet(opts)
  .then((result) => { res(result); })
  .catch((error) => { rej(error); });
});

const getters = {
  hasOrderDrafts: state => {
    return !!state.orderDraft && !!state.orderDraft.id  && !!state.orderDraft.familyId; // && !!state.orderDraft.agentId;
  },
  dropdownCustomersForNewOrder: state => {
    return state.customersForNewOrder.map(item => ({text: item.code + " - " + item.businessName, value: item.id}))
  },
  dropdownOrderTypes: state => {
    return state.orderTypes.map(item => ({text: item.description, value: item.id}))
  },
  dropdownPaymentTerms: state => {
    return state.paymentTerms.map(item => ({text: `${item.code} - ${item.description}`, value: item.id}))
  },
  dropdownPaymentMethods: state => {
    return state.paymentMethods.map(item => ({text: `${item.code} - ${item.description}`, value: item.id}))
  },
  dropdownOrderStatus: state => {
    return state.orderStatus.map(item => ({text: item.description, value: item.id}))
  },
  paymentTermDeadlinesTable: state => {
    return state.paymentTermDeadlines.map(item => ({
      line: item.line,
      expirationDate: item.expirationDate,
      amount: item.amount
    }))
  },


}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
