// Pathify
import {make} from 'vuex-pathify'
import utils from './utils';
const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const GeonameCountryApi = new MyfamilysrlNikitaSdk.GeonameCountryApi();
const GeonameRegionApi = new MyfamilysrlNikitaSdk.GeonameRegionApi();
const GeonameProvinceApi = new MyfamilysrlNikitaSdk.GeonameProvinceApi();
const GeonamePostalCodeApi = new MyfamilysrlNikitaSdk.GeonamePostalCodeApi();
const ZoneApi = new MyfamilysrlNikitaSdk.ZoneApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

// Data
const state = {
    countries: [],
    countryRegions: [],
    regionProvinces: [],
    provinceNames: [],  // viene utilizzato per memorizzare il nome e permetterne la visualizzazione
    zones: []
}

const mutations = make.mutations(state)

const actions = {
    ...make.actions(state),

    initCountryCollection: async ({dispatch}, force) => {
        if (state.countries.length === 0 || !!force) {
            dispatch('apiGeonameCountryGet', force);
        }
    },

    initZoneCollection: async ({dispatch}, force) => {
        if (state.zones.length === 0 || !!force) {
            dispatch('apiZoneGet', force);
        }
    },

    apiGeonameCountryGet: async ({dispatch}, force) => new Promise((res, rej) => {
        !!force && utils.actions.loadRes();
        GeonameCountryApi.apiGeonameCountryGet({MF_TENANT_ID})
            .then((result) => {
                dispatch('setCountries', result);
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                rej(error);
            })
            .finally(() => {
                !!force && utils.actions.unloadRes();
            });
    }),

    apiGeonameCountryIsocodeRegionsGet: async ({dispatch}, countryIso) => new Promise((res, rej) => {
        utils.actions.loadRes();
        GeonameCountryApi.apiGeonameCountryIsoRegionsGet(countryIso, {MF_TENANT_ID})
            .then((result) => {
                dispatch('setCountryRegions', result);
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiGeonameRegionIsocodeProvincesGet: async ({dispatch}, regionIso) => new Promise((res, rej) => {
        utils.actions.loadRes();
        GeonameRegionApi.apiGeonameRegionIsoProvincesGet(regionIso, {MF_TENANT_ID})
            .then((result) => {
                dispatch('setRegionProvinces', result);
                state.provinceNames.push(...result);   // 
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiGeonameProvinceIdGet: ({dispatch}, provinceId) => {
        // utils.actions.loadRes();
        if(!provinceId) return;
        let province = state.provinceNames.find(el => el.proviceId == provinceId);
        if(!province) {
            GeonameProvinceApi.apiGeonameProvinceIdGet(provinceId, {MF_TENANT_ID})
            .then((result) => { 
                state.provinceNames.push(result);
            })
        } else return province;
    },

    apiGeonamePostalCodeGet: async ({dispatch}, params) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let opts = {
            countryIsoCode: params.countryIsoCode,
            postalCode: params.postalCode,
            MF_TENANT_ID
        };
        GeonamePostalCodeApi.apiGeonamePostalCodeGet(opts)
            .then((result) => {
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                rej(error);
            })
        .finally(() => {
          utils.actions.unloadRes();
        });
    }),

    apiZoneGet: async ({dispatch}, force) => new Promise((res, rej) => {
        !!force && utils.actions.loadRes();
        ZoneApi.apiZoneGet({MF_TENANT_ID})
            .then((result) => {
                dispatch('setZones', result);
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                rej(error);
            })
            .finally(() => {
                !!force && utils.actions.unloadRes();
            });
    }),
}

const getters = {
    dropdownCountriesIso: state => {
        return state.countries
            .map(item => ({text: `${item.iso} - ${item.country}`, value: item.iso}));
    },
    dropdownCountriesId: state => {
        return state.countries
            // .sort((a,b) => a.country.localeCompare(b.country))
            .map(item => ({text: `${item.iso} - ${item.country}`, value: item.geonameId}));
    },
    dropdownRegions: state => {
        return state.countryRegions
            // .sort((a,b) => a.regionName.localeCompare(b.regionName))
            .map(item => ({text: `${item.regionName}`, value: item.regionId}));
    },
    dropdownProvinces: state => {
        return state.regionProvinces
            // .sort((a,b) => a.provinceName.localeCompare(b.provinceName))
            .map(item => ({text: `${item.provinceName}`, value: item.proviceId}));
    },
    dropdownZones: state => {
        return state.zones
            .map(item => ({text: item.description, value: item.id}));
    },
    province: (state) => (provinceId) => {
        return state.provinceNames.find(el => el.proviceId === provinceId);
    },
    country: (state) => (iso2) => {
        let result = state.countries.find(el => el?.iso === iso2);
        return `${result?.iso} - ${result?.country} (${result?.currencyCode} - ${result?.currencyName})`
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
