// Pathify
import {make} from 'vuex-pathify'
import utils from './utils';

const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const PriceCatalogApi = new MyfamilysrlNikitaSdk.PriceCatalogApi();
const ItemPriceApi = new MyfamilysrlNikitaSdk.ItemPriceApi();
const CustomersApi = new MyfamilysrlNikitaSdk.CustomersApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

// Data
const state = {
    priceCatalogs: [],
    itemPrices: [],
    itemPricesCatalog: null,
    priceCatalogsForEditOrder: []
}

const mutations = make.mutations(state)

const actions = {
    ...make.actions(state),

    initPriceCatalogCollection: async ({dispatch}, force) => {
        if (state.priceCatalogs.length === 0 || !!force) {
            dispatch('priceCatalogGet', force);
        }
    },

    apiCustomersIdPriceCatalogFamilyIdGet: async({dispatch}, opts = {}) => new Promise((resolve, reject) => {
        utils.actions.loadRes();
        const customerId = opts.customerId;
        const familyId = opts.familyId;
        CustomersApi.apiCustomersIdPriceCatalogFamilyIdGet(customerId, familyId, {MF_TENANT_ID})
            .then((result) => {
                dispatch('setPriceCatalogsForEditOrder', result);
                resolve(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                reject(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    getItemPriceListOfCatalog: async ({dispatch}, priceCatalogId) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let opts = {
            priceCatalogId: priceCatalogId,
            MF_TENANT_ID
        };
        ItemPriceApi.apiItemPriceGet(opts)
        .then((result) => {
            dispatch("setItemPrices", result);
            dispatch("setItemPricesCatalog", priceCatalogId);
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
    }),

    priceCatalogGet: async ({dispatch}, force) => new Promise((res, rej) => {
        !!force && utils.actions.loadRes();
        PriceCatalogApi.apiPriceCatalogGet({MF_TENANT_ID})
        .then((result) => {
            dispatch('setPriceCatalogs', result.sort((a, b) => a.code < b.code? -1 : 1));
            res(result);
        })
        .catch((error) => {
            rej(error);
        })
        .finally(() => {
          !!force && utils.actions.unloadRes();
        });
    }),

    priceCatalogPost: async ({dispatch}, payload) => new Promise((res, rej) => {
        let opts = {
            'priceCatalog': MyfamilysrlNikitaSdk.PriceCatalog.constructFromObject(payload),
            MF_TENANT_ID
        };
        PriceCatalogApi.apiPriceCatalogPost(opts)
        .then(() => {
            dispatch('priceCatalogGet');
            dispatch('app/showSuccess', 'completed', {root: true});
            res();
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
    }),

    priceCatalogPut: async ({dispatch}, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let opts = {
            'priceCatalog': MyfamilysrlNikitaSdk.PriceCatalog.constructFromObject(payload),
            MF_TENANT_ID
        };
        let stringId = `${payload.id}`;
        PriceCatalogApi.apiPriceCatalogIdPut(stringId, opts)
        .then(() => {
            dispatch('priceCatalogGet');
            res();
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
    }),

    priceCatalogDelete: async ({dispatch}, id) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let stringId = `${id}`;
        PriceCatalogApi.apiPriceCatalogIdDelete(stringId, {MF_TENANT_ID})
        .then(() => {
            dispatch('priceCatalogGet');
            res();
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
    }),

    apiPriceCatalogIdPatchFromListPatch: async ({dispatch}, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        // ['priceCatalogId']
        // ['itemsPrices']
        let opts = {
            'itemPricesCatalogDto': MyfamilysrlNikitaSdk.ItemPricesCatalogDto.constructFromObject(payload),
            MF_TENANT_ID
        };
        PriceCatalogApi.apiPriceCatalogIdPatchFromListPatch(payload.priceCatalogId, opts)
        .then(() => {
            dispatch('app/showSuccess', `Well done!`, {root: true});
            res();
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
    }),

}

const getters = {
    dropdownPriceCatalogs: state => {
        return state.priceCatalogs.map(item => ({text: item.code, value: item.id}));
    },
    dropdownPriceCatalogsForOrderEdit: state => {
        return state.priceCatalogsForEditOrder.map(item => {
           const result = {value: item.priceCatalogId};
           result['text'] = state.priceCatalogs.find(x => x.id === item.priceCatalogId)?.code;
           return result;
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
