// Pathify
import { dispatch, make } from "vuex-pathify";
import utils from "./utils";
import MyfamilysrlNikitaSdk from "@myfamilysrl/nikita_sdk";
const TntSdk = require("@myfamilysrl/tntsync_sdk");
const SendersApi = new TntSdk.SendersApi();
const DocumentsApi = new TntSdk.DocumentsApi();
const ShipmentsApi = new TntSdk.ShipmentsApi();
const PriceCheckerApi = new TntSdk.PriceCheckerApi();
const MF_TENANT_ID = localStorage.getItem("tenantUuid");
// { MF_TENANT_ID }

// Data
const state = {
  senders: [],
  documents: [],
  documentCategories: [],
  shipment: {},
  recipient: {},
  shipmentsFilter: {},
  ddtView: true,
  shipmentView: false,
  showFilters: false
};

const mutations = make.mutations(state);

const actions = {
  ...make.actions(state),

  switchToDdtView: ({dispatch}) => {
    state.ddtView = true;
    state.shipmentView = false;
  },

  switchToShipmentView: ({dispatch}) => {
    state.ddtView = false;
    state.shipmentView = true;
  },

  apiSendersGet: async ({ dispatch }, force = false) =>
    new Promise((res, rej) => {
      !!force && utils.actions.loadRes();
      SendersApi.apiSendersGet({ MF_TENANT_ID })
        .then((result) => {
          dispatch("setSenders", result);
          res(result);
        })
        .catch((error) => {
          dispatch(
            "app/showError",
            `${`${error.error || error}`.split(" at ")[0] || "Error! Try later"}`,
            { root: true },
          );
          rej();
        })
        .finally(() => {
          !!force && utils.actions.unloadRes();
        });
    }),

  apiDocumentsGetMetodoDocTypesGet: async ({ dispatch }, force = false) =>
    new Promise((res, rej) => {
      !!force && utils.actions.loadRes();
      DocumentsApi.apiDocumentsGetMetodoDocTypesGet({
        MF_TENANT_ID,
      })
        .then((result) => {
          dispatch(
            "setDocuments",
            result.sort((a, b) => (a.code <= b.code ? -1 : 1)),
          );
          res(result);
        })
        .catch((error) => {
          dispatch(
            "app/showError",
            `${`${error.error || error}`.split(" at ")[0] || "Error! Try later"}`,
            { root: true },
          );
          rej();
        })
        .finally(() => {
          !!force && utils.actions.unloadRes();
        });
    }),

  apiDocumentsGetMetodoDocCategoriesGet: async ({ dispatch }, force = false) =>
      new Promise((res, rej) => {
        !!force && utils.actions.loadRes();
        DocumentsApi.apiDocumentsGetMetodoDocCategoriesGet({
          MF_TENANT_ID,
        })
            .then((result) => {
              dispatch(
                  "setDocumentCategories",
                  result.sort((a, b) => (a.categoryId <= b.categoryId ? -1 : 1)),
              );
              res(result);
            })
            .catch((error) => {
              dispatch(
                  "app/showError",
                  `${`${error.error || error}`.split(" at ")[0] || "Error! Try later"}`,
                  { root: true },
              );
              rej();
            })
            .finally(() => {
              !!force && utils.actions.unloadRes();
            });
      }),

  apiDocumentsFilteredPost: async ({ dispatch }) => new Promise((res, rej) => {
    utils.actions.loadRes();
    DocumentsApi.apiDocumentsFilteredPost({
      shipmentDocumentFilters: state.shipmentsFilter,
      MF_TENANT_ID,
    })
    .then((result) => {
      //console.log(result);
      res(result);
    })
    .catch((error) => {
      dispatch(
        "app/showError",
        `${`${error.error || error}`.split(" at ")[0] || "Error! Try later"}`,
        { root: true },
      );
      rej();
    })
    .finally(utils.actions.unloadRes);
  }),

  apiShipmentsFilteredPost: async ({ dispatch }) => new Promise((res, rej) => {
    utils.actions.loadRes();
    ShipmentsApi.apiShipmentsFilteredPost({
      shipmentDocumentFilters: state.shipmentsFilter,
      MF_TENANT_ID,
    })
        .then((result) => {
          //console.log(result);
          res(result);
        })
        .catch((error) => {
          dispatch(
              "app/showError",
              `${`${error.error || error}`.split(" at ")[0] || "Error! Try later"}`,
              { root: true },
          );
          rej();
        })
        .finally(utils.actions.unloadRes);
  }),

  apiPriceCheckerPost: async ({ dispatch }, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      'consignmentDto': payload
    };

    PriceCheckerApi.apiPriceCheckerPost(opts)
        .then((result) => {
          //console.log(result);
          res(result);
        })
        .catch((error) => {
          dispatch(
              "app/showError",
              `${`${error.error || error}`.split(" at ")[0] || "Error! Try later"}`,
              { root: true },
          );
          rej();
        })
        .finally(utils.actions.unloadRes);
  }),
  
  apiDocumentsWithPackagesPost: async ({ dispatch }, collection = []) => new Promise((res, rej) => {
    if(!collection.length) return;
    utils.actions.loadRes();
    DocumentsApi.apiDocumentsWithPackagesPost({ requestBody: collection })
      .then((result) => {
        res(result);
      })
      .catch((error) => {
        dispatch(
          "app/showError",
          `${`${error.error || error}`.split(" at ")[0] || "Error! Try later"}`,
          { root: true },
        );
        rej();
      })
    .finally(utils.actions.unloadRes);
  }),

  apiCreateShipmentWithStatusPost: async ({ dispatch }, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();

    let opts = {
      'consignmentDto': payload
    };

    ShipmentsApi.apiShipmentsCreateShipmentWithStatusPost(opts)
        .then((result) => {
          res(result);
        })
        .catch((error) => {
          dispatch(
              "app/showError",
              `${`${error.error || error}`.split(" at ")[0] || "Error! Try later"}`,
              { root: true },
          );
          rej();
        })
        .finally(utils.actions.unloadRes);
  }),

  apiShipmentsHandleShipmentWithLabelsPost: async ({ dispatch }, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();

    let xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        utils.actions.unloadRes();

        if (xhr.status == 200)
          res(xhr.response);
        else {

          let responseText = "";
          if (xhr.responseType && xhr.responseType === "arraybuffer")
            responseText = new TextDecoder().decode(xhr.response);
          else
            responseText = xhr.response;

          const error = `${responseText ?? xhr.status + '-' + xhr.statusText}`;
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        }
      }
    };

    let url = `${process.env.VUE_APP_TNT_BASEPATH}/api/Shipments/createShipmentWithLabels`;

    switch(payload.shipmentAction) {
      case "I": xhr.responseType = 'arraybuffer'; break;
      case "R":
      case "M": url = `${process.env.VUE_APP_TNT_BASEPATH}/api/Shipments/reprintShipment`; xhr.responseType = 'arraybuffer'; break;
      case "D": url = `${process.env.VUE_APP_TNT_BASEPATH}/api/Shipments/cancelShipment`; break;
    }

    xhr.open("POST", url, true);
    xhr.setRequestHeader('Content-Type', 'application/json'); // 'text/plain'
    xhr.setRequestHeader('Authorization', TntSdk.ApiClient.instance.defaultHeaders["Authorization"]);
    xhr.setRequestHeader('Accept', 'application/pdf');
    xhr.setRequestHeader('Mf-Tenant-Id',MF_TENANT_ID);

    xhr.send(JSON.stringify(payload));
  }),

  apiBordereauExport: ({dispatch}, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        utils.actions.unloadRes();
        if (xhr.status == 200) {
          res(xhr.response);
        } else {
          const error = `${xhr.status} - ${xhr.statusText}`;
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          rej(error);
        }
      }
    };
    xhr.open("POST", `${process.env.VUE_APP_TNT_BASEPATH}/api/Reports/printConfirmedShipments`, true);
    xhr.setRequestHeader('Content-Type', 'application/json'); // 'text/plain'
    xhr.setRequestHeader('Authorization', TntSdk.ApiClient.instance.defaultHeaders["Authorization"]);;
    xhr.setRequestHeader('Accept', 'application/pdf');

    xhr.responseType = 'arraybuffer';

    xhr.send(JSON.stringify(payload));
  }),

  apiShipmentsIdGet: async ({dispatch}, shipmentId) => new Promise((resolve, reject) => {
    utils.actions.loadRes();
    ShipmentsApi.apiShipmentsIdGet(shipmentId, {MF_TENANT_ID})
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          reject(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  }),

  apiShipmentsUndoShipment: async({dispatch}, shipmentId) => new Promise((resolve, reject) => {
    utils.actions.loadRes();
    ShipmentsApi.apiShipmentsIdDelete(shipmentId)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
          reject(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
  })
};

const getters = {
  dropdownSenders: (state) =>
    state.senders?.map((item) => ({
      text: `${item.name} (${item.address}) - ${item.description}`,
      value: item.id,
    })),
  dropdownDocuments: (state) =>
    state.documents?.map((item) => ({
      text: `${item.code} - ${item.description}`,
      value: item.code,
    })),
  dropdownDocumentCategories: (state) =>
      state.documentCategories?.map((item) => ({
        text: `${item.description}`,
        value: item.categoryId,
      })),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
