// Pathify
import { make } from 'vuex-pathify'
import utils from './utils';
const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const CustomerOwnershipApi = new MyfamilysrlNikitaSdk.CustomerOwnershipApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

// Data
const state = {
    customerOwnerships: []
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),

  customerOwnershipsGet: async ({ dispatch }, customerId = null) => new Promise((res, rej) => {
    utils.actions.loadRes();
    const opts = {
      customerId: +customerId,
      MF_TENANT_ID
    }
    CustomerOwnershipApi.apiCustomerOwnershipGet(opts)
    .then((result) => { 
      dispatch('setCustomerOwnerships', result); 
      res(result);
    })
    .catch((error) => { 
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true}); 
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  customerOwnershipsPost: async ({ dispatch }, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      'customerOwnership': MyfamilysrlNikitaSdk.CustomerOwnership.constructFromObject(payload),
      MF_TENANT_ID
    };
    CustomerOwnershipApi.apiCustomerOwnershipsPost(opts)
    .then(() => {
      dispatch('customerOwnershipsGet');
      dispatch('app/showSuccess', 'completed', { root: true });
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  customerOwnershipsPut: async ({ dispatch }, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      'customerOwnership': MyfamilysrlNikitaSdk.CustomerOwnership.constructFromObject(payload),
      MF_TENANT_ID
    };
    let stringId = `${payload.id}`;
    CustomerOwnershipApi.apiCustomerOwnershipsIdPut(stringId, opts)
    .then(() => {
      dispatch('app/showSuccess', 'completed', { root: true });
      dispatch('customerOwnershipsGet');
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  customerOwnershipsDelete: async ({ dispatch }, id) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let stringId = `${id}`;
    CustomerOwnershipApi.apiCustomerOwnershipIdDelete(stringId, {MF_TENANT_ID})
    .then(() => {
      dispatch('app/showSuccess', 'completed', { root: true });
      dispatch('customerOwnershipsGet');
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej();
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

}

const getters = {
//   dropdownCustomerOwnerships: state => {
//     return state.customerOwnerships.map(item => ({text: item.name + " - " + item.address, value: item.id}))
//   },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
