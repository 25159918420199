// Pathify
import { Store } from 'vuex';
import {make} from 'vuex-pathify';
import router from '../../router';

import utils from './utils';

const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const ItemApi = new MyfamilysrlNikitaSdk.ItemApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

// Data
const state = {
    items: [],
    itemPrices: []
}

const mutations = make.mutations(state)

const actions = {
    ...make.actions(state),
    
    initItemCollection: async ({ dispatch }, force) => {
        if (state.items.length === 0 || !!force) {
            dispatch('apiItemGet', force);
        }
    },

    apiItemGet: async ({dispatch}, force) => new Promise((res, rej) => {
        !!force && utils.actions.loadRes();
        ItemApi.apiItemGet({MF_TENANT_ID})
        .then((result) => {
            dispatch('setItems', result);
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            !!force && utils.actions.unloadRes();
        });
    }),

    apiItemPost: async ({dispatch}, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let opts = {
            'itemResponseDto': MyfamilysrlNikitaSdk.Item.constructFromObject(payload),
            MF_TENANT_ID
        };
        opts.itemResponseDto.ItemImages = [];
        ItemApi.apiItemPost(opts)
        .then((result) => {
            dispatch("initItemCollection", true);
            dispatch('app/showSuccess', 'completed', {root: true});
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),

    apiItemIdPut: async ({dispatch}, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let opts = {
            'itemResponseDto': {
                ...payload,
                ...MyfamilysrlNikitaSdk.Item.constructFromObject(payload)
            },
            MF_TENANT_ID
        };
        let stringId = `${payload.id}`;
        ItemApi.apiItemIdPut(stringId, opts)
        .then(() => {
            dispatch('app/showSuccess', 'completed', { root: true });
            dispatch('apiItemGet');
            res();
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),

    apiItemIdDelete: async ({dispatch}, id) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let stringId = `${id}`;
        ItemApi.apiItemIdDelete(stringId, {MF_TENANT_ID})
        .then(() => {
            dispatch('app/showSuccess', 'completed', { root: true });
            dispatch('apiItemGet');
            res();
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error)
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),

    apiItemIdItemPricesGet: async ({dispatch}, itemId) => new Promise((res, rej) => {
        utils.actions.loadRes();
        ItemApi.apiItemIdItemPricesGet(itemId, {MF_TENANT_ID})
        .then((result) => {
            dispatch('setItemPrices', result);
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),

    itemPriceIdPatch: async ({dispatch}, collection) => new Promise((res, rej) => {
        utils.actions.loadRes()
        if (!!collection.length) {
            Promise.all(collection.map((el) => ItemApi.apiItemIdItemPricesPatch(el.itemId, {
                'itemPriceDto': [MyfamilysrlNikitaSdk.ItemPriceDto.constructFromObject(el)],
                MF_TENANT_ID
            })))
            .then((result) => {
                dispatch('app/showSuccess', 'completed', { root: true });
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                rej(error);
            })
            .finally(() => {
                dispatch('apiItemGet');
                utils.actions.unloadRes();
            });
        } else {
            rej();
            utils.actions.unloadRes();
        }
    }),

    applyFilters: async ({dispatch}, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let opts = {
            itemFilters: MyfamilysrlNikitaSdk.ItemFilters.constructFromObject(payload),
            MF_TENANT_ID
        }
        ItemApi.apiItemFilteredPost(opts)
        .then((result) => {
            dispatch('setItems', result);
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),

    apiItemIdAssignBarcodePost: async ({dispatch}, itemId) => new Promise((res, rej) => {
        utils.actions.loadRes();
        ItemApi.apiItemIdAssignBarcodePost(itemId, {MF_TENANT_ID})
        .then((result) => {
            dispatch('app/showSuccess', 'completed', { root: true });
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            dispatch('apiItemGet')
            .then(() => {
                dispatch('app/showSuccess', 'completed', {root: true});
            });
            utils.actions.unloadRes();
        });
    }), 

    handleUnevailableItems: async ({dispatch}, orderDraftRows) => new Promise((res, rej) => {
        orderDraftRows.map((row) => {
            let item = state.items.find(el => el.code === (row.itemCode || row.code));
            row.notes = row.notes || "";
            if (!!item && !!item.obsolete && !item.active) {
                if(!row.notes.includes("*OUT OF STOCK*")) row.notes += " *OUT OF STOCK*";
            } else if (!!item && !!item.obsolete) {
                if(!row.notes.includes("*OBSOLETE*")) row.notes += " *OBSOLETE*";
            } else if (!!item && !item.active) {
                if(!row.notes.includes("*UNAVAILABLE*")) row.notes += " *UNAVAILABLE*";
            }
        });
        res();    
    }), 

    removeUnavailableItems: async ({dispatch}, orderDraftRows) => new Promise((res, rej) => {
        let errors = { 
            unavailable : [], 
            obsolete : [],
            out_of_stock : [] 
        };
        orderDraftRows.forEach(row => {
            let item = state.items.find(el => el.code === (row.itemCode || row.code));
            if (!!item && !!item.obsolete && !item.active) errors.out_of_stock.push(item.code);
            else if (!!item && !!item.obsolete) errors.obsolete.push(item.code);
            else if (!!item && !item.active) errors.unavailable.push(item.code);
        });

        let errorMessage = `
            ${errors.out_of_stock.length? `OUT OF STOCK items : ${ errors.out_of_stock.join(`, `) };` : ``} 
            ${errors.unavailable.length? `UNAVAILABLE items : ${ errors.unavailable.join(`, `) };` : ``} 
            ${errors.obsolete.length? `OBSOLETE items : ${ errors.obsolete.join(`, `) };` : ``}`

        if (!!(errors.out_of_stock.length + errors.unavailable.length + errors.obsolete.length)) dispatch('app/showError', errorMessage, {root: true});
        
        res({
            collection : orderDraftRows.filter(el => !!state.items.find(x => x.code === (el.itemCode || el.code) && x.active && !x.obsolete)),
            message: errorMessage,
        });
        
    }),
    
    apiItemIdDuplicatePost: async ({dispatch}, item) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let resId = null;
        console.log(state.items.length);
        ItemApi.apiItemIdDuplicatePost(item.id, {MF_TENANT_ID})
        .then((result) => {
            resId = result.id;
            dispatch('app/showSuccess', 'completed', { root: true });
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            dispatch('initItemCollection', true)
            
            dispatch('app/showSuccess', 'completed', {root: true});
            
            window.open(`${window.location.origin}/items/${ resId }`);
            
            utils.actions.unloadRes();
        });
    }), 
}

const getters = {
    singleItemPricesList: state => {
        return state.itemPrices;
    },
    dropdownItems: state => state.items.map(item => ({text: item.code + " - " + item.description, value: item.code})),
    getDescriptionByCode: state => code => state.items.find(x => x.code == code)?.description ?? "MISSING DESCRPTION",
    getItemByCode: state => code => state.items.find(x => x.code == code),
    getDescriptionById: state => id => state.items.find(x => x.id == id)?.description ?? "MISSING DESCRPTION",
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
