// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  drawer: null,
  drawerImage: true,
  mini: false,
  alert: { show: false, message: '', type: 'success', duration: 1000},
  confirm: { show: false, message: '', type: 'info', action: () => {}},
  items: [],
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {},
  dismissAlert: async ({ dispatch }) => {
    dispatch('setAlert', { show: false, message: "", type: 'error'});
  },
  showError: async ({ dispatch }, error) => {
    console.error(error);
    let str;
    dispatch('extractJSON', error)
    .then((response) => {
      str = response;
    })
    .finally(() => {
      dispatch('setAlert', { 
        show: true, 
        message: typeof error === 'object' 
          ? `${error.error.message} ${!!error.body ? `: ${error.body}` : ''}` 
          : str?.errorMessage || error, type: 'error' });
    })
  },
  showSuccess: async ({ dispatch }, message) => {
    dispatch('setAlert', { show: true, message: message, type: 'success' });
  },
  showWarning: async ({ dispatch }, message) => {
    dispatch('setAlert', { show: true, message: message, type: 'warning' });
  },
  showConfirm: async ({ dispatch }, o) => {
    dispatch('setAlert', { show: true, message: o.message, action: o.action, params: o.params, type: 'info' });
  },
  extractJSON: ({dispatch}, str) => {
    var firstOpen, firstClose, candidate;
    firstOpen = str.indexOf('{', firstOpen + 1);
    do {
        firstClose = str.lastIndexOf('}');
        if(firstClose <= firstOpen) {
            return null;
        }
        do {
            candidate = str.substring(firstOpen, firstClose + 1);
            try {
                var res = JSON.parse(candidate);
                return res;
            }
            catch(e) {
                console.error('...failed');
            }
            firstClose = str.substr(0, firstClose).lastIndexOf('}');
        } while(firstClose > firstOpen);
        firstOpen = str.indexOf('{', firstOpen + 1);
    } while(firstOpen != -1);
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
