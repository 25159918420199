import {make} from 'vuex-pathify'

var GeocoderGeonames = require('geocoder-geonames'),
geocoder = new GeocoderGeonames({ username: 'a.quaglia' });
const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const GeonameCountryApi = new MyfamilysrlNikitaSdk.GeonameCountryApi();

// Data
const state = {
  isLoading: 0,
  pullDown: false,
  calendarDialod: false
}

const mutations = make.mutations(state)

const actions = {
  
  ...make.actions(state),
  
  getGeoNames : ({ dispatch }, query) => {

    if (!!query && !!query.length) return geocoder.get('search',{ q: query, maxRows : 10 });

    // servizio esistente ma NON funzionante
    // // if (!!queryObj.postalCode && !!queryObj.postalCode.length) return geocoder.get('postalCodeLookup',{ 
    // //     postalCode: queryObj.postaCode 
    // // });
    // servizio NON esistente
    // // if (!!queryObj.coordinates && !!queryObj.coordinates.length) return geocoder.get('address',{ 
    // //     lat: queryObj.coordinates[0], 
    // //     lng: queryObj.coordinates[1] 
    // // });
      
  },

  splitArray : ({dispatch}, obj = {}) => {
    let collection = obj.collection || [], len = obj.len || 12, chunks = [],  i = 0, n = collection.length;
    while (i < n) { chunks.push(collection.slice(i, i += len)) }
    return chunks;
  },

  loadRes : () => { 
    state.isLoading = state.isLoading + 1;
  },

  unloadRes : () => { 
    state.isLoading = state.isLoading - 1; 
  }, 

  triggerPullDown : () => {
    state.pullDown = !state.pullDown;
  },

  triggerCalendarDialog : () => {
    state.calendarDialod = !state.calendarDialod;
  },

  downloadPdf: ({dispatch}, obj) => {
    var binaryData = [];
    binaryData.push(obj.content);
    const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/pdf' }));
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${obj.filename}.pdf`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  },

  downloadExcel: function (filename, content) {
    var binaryData = [];
    binaryData.push(content);
    const url = window.URL.createObjectURL(new Blob(binaryData));
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${filename}.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
    
}

const getters = {
  numberRules : function () {
    return [
      (v) => typeof v === 'number' && isFinite(v) || "Invalid number"
    ];
  },
  positiveNumberRules : function() {
    return [
      (v) => typeof v === 'number' && isFinite(v) || "Invalid number",
      (v) => v >= 0 || "Only positive number allowed"
    ];
  },
  negativeNumberRules : function() {
    return [
      (v) => typeof v === 'number' && isFinite(v) || "Invalid number",
      (v) => v <= 0 || "Only positive number allowed"
    ];
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters
}
