// Pathify
import { make } from 'vuex-pathify'
import utils from './utils';
const IntesaEdiSdk = require('@myfamilysrl/intesaedi-sdk');
const EdiDataApi = new IntesaEdiSdk.EdiDataApi;
const MetodoDocPreviewApi = new IntesaEdiSdk.MetodoDocPreviewApi;
const EdiManagementApi = new IntesaEdiSdk.EdiManagementApi;

// Data
const state = {
  orderCollection: [],
  orderStatusCollection: [],
  orderTypeCollection: [],
  metodoDocTypeCollection: [],
  orderConfirmRequestDto: {
    year: null,
    code: null,
    bis: null,
    type: null
  }
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),

  initOrdersCollection: async (dispatch, force) => new Promise((res, rej) => {
    if (state.orderCollection.length === 0 || !!force) {
      actions.apiEdiDataEdiDocumentsGet(dispatch, force)
          .then((response) => {
            dispatch('setOrderCollection', response.sort((a, b) => a.id - b.id));
            res(response)
          })
          .catch((error) => rej(error));
    } else {
      res(state.orders);
    }
  }),

  apiEdiDataEdiDocumentsGet: async ({ dispatch }, force) => new Promise((res, rej) => {
    if (state.orderCollection.length === 0 || !!force) {
      !!force && utils.actions.loadRes();
      EdiDataApi.apiEdiDataEdiDocumentsGet()
      .then((result) => {
        dispatch('setOrderCollection', result.sort((a, b) => a.id - b.id));
        res(result.sort((a, b) => b.id - a.id));
      })
      .catch((error) => {
        dispatch('app/showError', `${`${error.error || error}`.split('at')[0] || 'Error! Try later' }`, {root: true});
        rej(error);
      })
      .finally(() => {
        !!force && utils.actions.unloadRes();
      });
    } else res(state.orders);
  }),

  apiEdiDataEdiDataOrderStatusGet: async ({ dispatch }, force) => new Promise((res, rej) => {
    !!force && utils.actions.loadRes();
    EdiDataApi.apiEdiDataEdiDataOrderStatusGet()
    .then((result) => {
      dispatch('setOrderStatusCollection', result);
      res(result);
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split('at')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      !!force && utils.actions.unloadRes();
    });
  }),

  apiEdiDataEdiDataOrdersTypesGet: async ({ dispatch }, force) => new Promise((res, rej) => {
    !!force && utils.actions.loadRes();
    EdiDataApi.apiEdiDataEdiDataOrdersTypesGet()
    .then((result) => {
      dispatch('setOrderTypeCollection', result);
      res(result);
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split('at')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      !!force && utils.actions.unloadRes();
    });
  }),



  apiEdiManagementMultiservicePost: async ({ dispatch }, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    EdiManagementApi.apiEdiManagementMultiservicePost({ 
      multiserviceRequestDto: payload 
    })
    .then((result) => {
      res(result);
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split('at')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => { utils.actions.unloadRes() });
    // utils.actions.loadRes();
    // var xhr = new XMLHttpRequest();
    // xhr.onreadystatechange = () => {
    //   if (xhr.readyState === 4) {
    //     utils.actions.unloadRes();
    //     if (xhr.status === 200) {
    //       res(xhr.response);
    //     } else {
    //       const error = `${xhr.status} - ${xhr.statusText}`;
    //       dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
    //       rej(error);
    //     }
    //   }
    // };
    // xhr.open("POST", `${process.env.VUE_APP_EDI_BASEPATH}/api/EdiManagement/Multiservice`, true);
    // xhr.setRequestHeader('Content-Type', 'application/json'); // 'text/plain'
    // xhr.setRequestHeader('Authorization', IntesaEdiSdk.ApiClient.instance.defaultHeaders["Authorization"]);
    // xhr.setRequestHeader('Accept', 'application/xml');
    // xhr.responseType = 'arraybuffer';
    // xhr.send(JSON.stringify({ multiserviceRequestDto: payload }));
  }),

  apiMetodoDocPreviewMetodoDocTypesGet: ({ dispatch }, force) => new Promise((res, rej) => {
    !!force && utils.actions.loadRes();
    MetodoDocPreviewApi.apiMetodoDocPreviewMetodoDocTypesGet()
    .then((result) => {
      dispatch('setMetodoDocTypeCollection', result);
      res(result);
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split('at')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      !!force && utils.actions.unloadRes();
    });
  }),

  apiMetodoDocPreviewMetodoDocPreviewPost: async ({dispatch}) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      orderConfirmRequestDto: IntesaEdiSdk.OrderConfirmRequestDto.constructFromObject(state.orderConfirmRequestDto)
    }
    opts.orderConfirmRequestDto.bis = opts.orderConfirmRequestDto.bis ?? '';
    MetodoDocPreviewApi.apiMetodoDocPreviewMetodoDocPreviewPost(opts)
    .then((result) => {
      res(result);
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split('at')[0] || 'Error! Try later' }`, {root: true});
      
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  clearOrderConfirmRequestDto: async ({dispatch}) => new Promise((res, rej) => {
    Object.keys(state.orderConfirmRequestDto).forEach(k => state.orderConfirmRequestDto[k] = null );
    res();
  })

}

const getters = {
  readableOrderStatus: state => (id) => state.orderStatusCollection.find(el => el.id == id)?.description ?? '-',
  readableOrderType: state => (id) => state.orderTypeCollection.find(el => el.id == id)?.description ?? '-',
  metodoDocTypeCollectionDropdown: state => {
    return state.metodoDocTypeCollection.map(item => ({ text: `${item.code} - ${item.description}`, value: item.code }));
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
