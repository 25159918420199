// Pathify
import { get } from 'request';
import { make } from 'vuex-pathify'
import utils from './utils';
import tenantConfiguration from './tenantConfiguration';
const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const VatPercentageApi = new MyfamilysrlNikitaSdk.VatPercentageApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }
let initializing = {
    vatCollection: false,
}

// Data
const state = {
    vatPercentages: []
}

const mutations = make.mutations(state)

const actions = {
    ...make.actions(state),

    initVatCollection: async (dispatch, force) => new Promise((res, rej) => {
        if (state.vatPercentages.length === 0 || !!force) {
            if (!initializing.vatCollection) {
                initializing.vatCollection = true;
                actions.apiVatPercentageGet(dispatch, force)
                    .then((response) => res(response))
                    .catch((error) => rej(error))
                    .finally(() => {
                        initializing.vatCollection = false;
                    })
            } else {
                res(state.vatPercentages);
            }
        }
    }),

    apiVatPercentageGet: async ({ dispatch }, force) => new Promise((res, rej) => {
        !!force && utils.actions.loadRes();
        VatPercentageApi.apiVatPercentageGet({ MF_TENANT_ID })
            .then((result) => {
                dispatch('setVatPercentages', result);
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                !!force && utils.actions.unloadRes();
            });
    }),

    apiVatPercentagePost: async ({ dispatch }, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        payload.tenantUuid = payload.tenantUuid || tenantConfiguration.state.tenantUuid;
        let opts = {
            'vatPercentage': MyfamilysrlNikitaSdk.VatPercentage.constructFromObject(payload),
            MF_TENANT_ID
        };
        VatPercentageApi.apiVatPercentagePost(opts)
            .then(() => {
                dispatch('apiVatPercentageGet');
                dispatch('app/showSuccess', 'completed', { root: true });
                res();
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiVatPercentageIdPut: async ({ dispatch }, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let opts = {
            'vatPercentage': MyfamilysrlNikitaSdk.VatPercentage.constructFromObject(payload),
            MF_TENANT_ID
        };
        let stringId = `${payload.id}`;
        VatPercentageApi.apiVatPercentageIdPut(stringId, opts)
            .then(() => {
                dispatch('apiVatPercentageGet');
                res();
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiVatPercentageIdDelete: async ({ dispatch }, id) => new Promise((res, rej) => {
        let stringId = `${id}`;
        VatPercentageApi.apiVatPercentageIdDelete(stringId, { MF_TENANT_ID })
            .then(() => {
                dispatch('apiVatPercentageGet');
                res();
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later'}`, { root: true });
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

}

const getters = {
    vatPercentageById: state => (id) => {
        return state.vatPercentages.find(el => el.id == id);
    },
    dropdownVatPercentages: state => {
        return state.vatPercentages.map(item => ({
            text: `${item?.code ?? ''} - ${item?.description ?? ''} (${item.percentage ?? 0}%)`,
            value: item.id
        }))
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
