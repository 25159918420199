// Pathify
import { dispatch, make } from 'vuex-pathify';
import utils from './utils';
const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const TagsApi = new MyfamilysrlNikitaSdk.TagApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

// Data
const state = {
  tags: [],
  inUse: false
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),

  initTagCollection: async ({ dispatch }, force = false) => {
    if (state.tags.length === 0 || !!force) {
      dispatch('apiTagGet', force);
    }
  },

  apiTagGet: async ({ dispatch }, force = false) => new Promise((res, rej) => {
    if(!!state.inUse) {
      console.log("skipped apiTagGet");
    } else {
      state.inUse = true;
      !!force && utils.actions.loadRes();
      TagsApi.apiTagGet({MF_TENANT_ID})
      .then((result) => {
        dispatch('setTags', result);
        res();
      })
      .catch((error) => {
        dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
        rej();
      })
      .finally(() => {
        state.inUse = false;
        !!force && utils.actions.unloadRes();
      });
    }
  }),

}

const getters = {
  dropdownTags: state => {
    return state.tags?.map(item => ({ text: `${item.value}`, value: item.id })); //${item.key} - 
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
