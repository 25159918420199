// Pathify
import {make} from 'vuex-pathify'
import utils from './utils';

const NikitaSdk = require("@myfamilysrl/nikita_sdk");
const ZoneGoalApi = new NikitaSdk.ZoneGoalApi();
const MonitoringDataApi = new NikitaSdk.MonitoringDataApi();
const OrderStatisticalDataApi = new NikitaSdk.OrderStatisticalDataApi();
const OrderStatisticalTagApi = new NikitaSdk.OrderStatisticalTagApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

// Data
const state = {
    filter: {
        geonameCountryCode: null,
        geonameRegionCode: null,
        geonameProvinceCode: null,
        statisticalTags: [],
        upToDateTime: null,
        groupByStatisticalTag: false
    },
    zoneGoalCollection: [],
    orderStatsCollection: [],
    orderStatisticalTagCollection: [],
    orderStatisticalRowCollection: [],
}

const mutations = make.mutations(state)

const actions = {
    ...make.actions(state),

    apiZoneGoalGet: ({dispatch}) => new Promise((resolve, reject) => {
        utils.actions.loadRes();
        ZoneGoalApi.apiZoneGoalGet({MF_TENANT_ID})
            .then((result) => {
                dispatch("setZoneGoalCollection", result);
                resolve(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                reject(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiZoneGoalFilteredGet: ({dispatch}) => new Promise((resolve, reject) => {
        utils.actions.loadRes();
        ZoneGoalApi.apiZoneGoalFilteredGet(state.filter, {MF_TENANT_ID})
            .then((result) => {
                dispatch("setZoneGoalCollection", result);
                resolve(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                reject(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiOrderStatisticalDataFilteredGet: ({dispatch}) => new Promise((resolve, reject) => {
        utils.actions.loadRes();
        MonitoringDataApi.apiOrderStatisticalDataFilteredGet(state.filter, {MF_TENANT_ID})
            .then((result) => {
                dispatch("setOrderStatsCollection", result);
                resolve(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                reject(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiStatisticalTagGet: ({dispatch}) => new Promise((resolve, reject) => {
        utils.actions.loadRes();
        StatisticalTagApi.apiStatisticalTagGet()
        .then((result) => {
            dispatch("setStatisticalTagCollection", result);
            resolve(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            reject(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),

    apiOrderStatisticalTagGet: ({dispatch}) => new Promise((resolve, reject) => {
        utils.actions.loadRes();
        OrderStatisticalTagApi.apiOrderStatisticalTagGet({MF_TENANT_ID})
        .then((result) => {
            dispatch("setOrderStatisticalTagCollection", result);
            resolve(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            reject(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),

    apiOrderStatisticalDataFilteredPost: ({dispatch}, payload) => new Promise((resolve, reject) => {
        utils.actions.loadRes();
        let opts = {
            orderStatisticalDataFilters: NikitaSdk.OrderStatisticalDataFilters.constructFromObject(payload),
            MF_TENANT_ID
        }
        OrderStatisticalDataApi.apiOrderStatisticalDataFilteredPost(opts)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                reject(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiOrderStatisticalDataWithOrderStatisticalTagIdOrderStatisticalTagIdPost: ({dispatch}, payload) => new Promise((resolve, reject) => {
        utils.actions.loadRes();
        let opts = {
            orderStatisticalDataWithOSTIdRequestDto: NikitaSdk.OrderStatisticalDataWithOSTIdRequestDto.constructFromObject(payload),
            MF_TENANT_ID
        }
        if(!!opts.orderStatisticalDataWithOSTIdRequestDto.orderStatisticalTagId && !opts.orderStatisticalDataWithOSTIdRequestDto.orderStatisticalTagId.filter(x => !!x).length) {
            opts.orderStatisticalDataWithOSTIdRequestDto.orderStatisticalTagId = null;
        }
        OrderStatisticalDataApi.apiOrderStatisticalDataWithOrderStatisticalTagIdOrderStatisticalTagIdPost(1, opts)
            .then((result) => {
                dispatch("setOrderStatisticalRowCollection", result);
                resolve(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                reject(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),
}

const getters = {
    orderStatisticalTagDropdown: state => {
        return state.orderStatisticalTagCollection.map(item => ({text: item.value, value: item.id}));
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
