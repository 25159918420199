import { make } from 'vuex-pathify'

const state = {
  tokens: {},
  userProfile: {
      userRoles: []
  }
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
}

const getters = {
  isInRole: (state) => (roles) => {
    if (!roles) { return true; }
    if (typeof roles === "string") { roles = [roles]; }
    let result = false;
    for(const i in roles) {
        if (!!result) { break; }
        const role = roles[i];
        if (state.userProfile.userRoles.includes(role)) { result = true; }
    }
    return result;
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

