// Pathify
import {make} from 'vuex-pathify';
import router from '../../router';
import utils from './utils';

const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const ItemKitApi = new MyfamilysrlNikitaSdk.ItemKitApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

// Data
const state = {
    itemKits: [],
    itemKitRows: [],
}

const mutations = make.mutations(state)

const actions = {
    ...make.actions(state),

    initItemKitCollection: async ({ dispatch }, force) => {
        if (state.itemKits.length == 0 || !!force) {
            dispatch('apiItemKitGet', force);
        }
    },
    
    //////////////////////
    //       GET        //
    //////////////////////

    // GET all
    apiItemKitGet: async ({dispatch}, force = true) => new Promise((res, rej) => {
        !!force && utils.actions.loadRes();
        if (state.itemKits.length == 0 || !!force) {
            ItemKitApi.apiItemKitGet({MF_TENANT_ID})
            .then((result) => {
                dispatch('setItemKits', result);
                res();
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                rej(error);
            })
            .finally(() => {
              !!force && utils.actions.unloadRes();
            });
        } else {
            !!force && utils.actions.unloadRes();
            res();
        }
    }),
    // GET single ID
    apiItemKitIdGet: async ({dispatch}, id) => new Promise((res, rej) => {
        utils.actions.loadRes();
        ItemKitApi.apiItemKitIdGet(+id, {MF_TENANT_ID})
        .then((result) => {
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
    }),
    // GET rows from ID
    apiItemKitIdItemKitRowsGet: async ({dispatch}, id) => new Promise((res, rej) => {
        utils.actions.loadRes();
        if (!state.itemKitRows.some(x => x.itemKitId === id)) {
            ItemKitApi.apiItemKitIdItemKitRowsGet(id, {MF_TENANT_ID})
            .then((result) => {
                const data = state.itemKitRows.slice();
                data.push(...result);
                dispatch('setItemKitRows', data);
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                rej(error);
            })
            .finally(() => {
              utils.actions.unloadRes();
            });
        } else {
            utils.actions.unloadRes();
            res(state.itemKitRows.filter(x => x.itemKitId === id));
        }
    }),
    
    //////////////////////
    //       POST       //
    //////////////////////
    // create new Kit
    apiItemKitPost : async ({ dispatch }, kitObj) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let opts = {
            itemKit : MyfamilysrlNikitaSdk.ItemKit.constructFromObject(kitObj),
            MF_TENANT_ID
        }
        ItemKitApi.apiItemKitPost(opts)
        .then((result) => {
            dispatch('apiItemKitGet');
            dispatch('app/showSuccess', 'completed', {root: true});
            router.push("/kits/" + result.id);
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
    }),
    
    //////////////////////
    //        PUT       //
    //////////////////////
    // update Kit
    apiItemKitIdPut : async ({ dispatch }, kitObj) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let opts = {
            itemKit : MyfamilysrlNikitaSdk.ItemKit.constructFromObject(kitObj),
            MF_TENANT_ID
        }
        ItemKitApi.apiItemKitIdPut(kitObj.id, opts)
        .then((result) => {
            dispatch('apiItemKitGet');
            dispatch('app/showSuccess', 'completed', {root: true})
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
    }),
    
    //////////////////////
    //      PATCH       //
    //////////////////////
    // patch kitRows
    apiItemKitIdItemKitRowsPatch : async ({ dispatch }, obj) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let opts = {
            'itemKitRow' : [...obj.kitRows.map(el => MyfamilysrlNikitaSdk.ItemKitRow.constructFromObject(el))],
            MF_TENANT_ID
        }
        ItemKitApi.apiItemKitIdItemKitRowsPatch(obj.id, opts)
        .then((result) => {
            dispatch('app/showSuccess', 'completed', {root: true});
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
    }),

    //////////////////////
    //      DELETE      //
    //////////////////////
    apiItemKitIdDelete : async ({ dispatch }, id) => new Promise((res, rej) => {
        utils.actions.loadRes();
        ItemKitApi.apiItemKitIdDelete(id, {MF_TENANT_ID})
        .then((result) => {
            dispatch('apiItemKitGet');
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
    }),
}

const getters = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
