// Pathify
import { Store } from 'vuex';
import {make} from 'vuex-pathify';
import router from '../../router';

import utils from './utils';

const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const CustomersApi = new MyfamilysrlNikitaSdk.CustomersApi();
const TicketApi = new MyfamilysrlNikitaSdk.TicketApi();
const MessagesApi = new MyfamilysrlNikitaSdk.MessagesApi();
const ReleaseNoteApi = new MyfamilysrlNikitaSdk.ReleaseNoteApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

// Data
const state = {
    tickets: [],
    messages: [],
    logs: [],
    tags: [],
    releaseNotes: [],
    showRelease: false,
}

const mutations = make.mutations(state)

const actions = {
    ...make.actions(state),
    
    // initTicketCollection: async ({ dispatch }, force) => {
    //     if (state.notifications.length === 0 || !!force) {
    //         dispatch('apiCustomersIdTagsGet', force);
    //     }
    // },

    apiTicketAssignedToGet: async ({dispatch}) => new Promise((res, rej) => {
        utils.actions.loadRes();
        TicketApi
        .apiTicketAssignedToPendingGet({MF_TENANT_ID})
        .then((result) => {
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),

    // HANDLE TICKETS
    //! non esiste!
    // apiTicketGet: async ({dispatch}) => new Promise((res, rej) => {
    //     // let result = [];
    //     // dispatch('setTickets', result);
    //     // res(result);
    //     utils.actions.loadRes();
    //     TicketApi.apiTicketGet()
    //     .then((result) => {
    //         dispatch('setTickets', result);
    //         res(result);
    //     })
    //     .catch((error) => {
    //         dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
    //         rej(error);
    //     })
    //     .finally(() => {
    //         utils.actions.unloadRes();
    //     });
    // }),
    apiTicketPost: async ({dispatch}, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let opts = {
            'ticket': MyfamilysrlNikitaSdk.TicketDto.constructFromObject(payload),
            MF_TENANT_ID
        };
        TicketApi.apiTicketPost(opts)
        .then((result) => {
            dispatch('apiTicketGet').then(() => {
                dispatch('app/showSuccess', 'completed', {root: true})
            });
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),
    apiTicketIdGet: async ({dispatch}, ticketId) => new Promise((res, rej) => {
        utils.actions.loadRes();
        TicketApi.apiTicketIdGet(ticketId, {MF_TENANT_ID})
        .then((result) => {
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),
    apiTicketIdPut: async ({dispatch}, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let id = payload.id,
            opts = {
            'ticket': MyfamilysrlNikitaSdk.TicketDto.constructFromObject(payload),
            MF_TENANT_ID
        };
        TicketApi.apiTicketIdPut(id, opts)
        .then((result) => {
            dispatch('apiTicketGet').then(() => {
                dispatch('app/showSuccess', 'completed', {root: true})
            });
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),
    apiTicketIdDelete: async ({dispatch}, ticketId) => new Promise((res, rej) => {
        utils.actions.loadRes();
        TicketApi.apiTicketIdDelete(ticketId, {MF_TENANT_ID})
        .then((result) => {
            dispatch('apiTicketGet').then(() => {
                dispatch('app/showSuccess', 'completed', {root: true})
            });
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),
    
    // BASED ON CUSTOMER
    // apiCustomersIdTagsGet: async ({dispatch}, customerId) => new Promise((res, rej) => {
    //     utils.actions.loadRes();
    //     CustomersApi.apiCustomersIdTagsGet(customerId, {MF_TENANT_ID})
    //     .then((result) => {
    //         dispatch('setTags', result);
    //         res(result);
    //     })
    //     .catch((error) => {
    //         dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
    //         rej(error);
    //     })
    //     .finally(() => {
    //         utils.actions.unloadRes();
    //     });
    // }),
    apiCustomersIdMessagesGet: async ({dispatch}, customerId) => new Promise((res, rej) => {
        utils.actions.loadRes();
        CustomersApi.apiCustomersIdMessagesGet(customerId, {MF_TENANT_ID})
        .then((result) => {
            dispatch('setMessages', result);
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),
    apiCustomersIdLogsGet: async ({dispatch}, customerId) => new Promise((res, rej) => {
        utils.actions.loadRes();
        CustomersApi.apiCustomersIdLogsGet(customerId, {MF_TENANT_ID})
        .then((result) => {
            dispatch('setLogs', result);
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),
    apiCustomersIdTicketsGet: async ({dispatch}, customerId) => new Promise((res, rej) => {
        // let result = [];
        // dispatch('setTickets', result);
        // res(result);
        utils.actions.loadRes();
        CustomersApi.apiCustomersIdTicketsGet(customerId, {MF_TENANT_ID})
        .then((result) => {
            dispatch('setTickets', result);
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),
    apiCustomersIdTicketPost: async ({dispatch}, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let customerId = payload.customerId,
            opts = { 
                'ticket': MyfamilysrlNikitaSdk.Ticket.constructFromObject(payload),
                MF_TENANT_ID 
            };
        CustomersApi.apiCustomersIdTicketPost(customerId, opts)
        .then((result) => {
            dispatch('apiCustomersIdTicketsGet').then(() => {
                dispatch('app/showSuccess', 'completed', {root: true})
            });
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),
    apiTicketCustomerCustomerIdGet: async ({dispatch}, customerId) => new Promise((res, rej) => {
        utils.actions.loadRes();
        TicketApi.apiTicketCustomerCustomerIdGet(customerId, {MF_TENANT_ID})
        .then((response) => {
            res(response);
        })
        .catch((error) => {
            rej(error);
        })
        .finally(utils.actions.unloadRes);
    }),
    apiTicketCustomerCustomerIdPost: async ({dispatch}, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let customerId = payload.entityId,
            opts = { 'ticketDto': MyfamilysrlNikitaSdk.TicketDto.constructFromObject(payload), MF_TENANT_ID };
        TicketApi.apiTicketCustomerCustomerIdPost(customerId, opts)
        .then((response) => {
            res(response);
        })
        .catch((error) => {
            rej(error);
        })
        .finally(utils.actions.unloadRes);
    }),
    apiTicketIdCustomerCustomerIdPut: async ({dispatch}, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let id = payload.id,
            customerId = payload.entityId,
            opts = {
                'ticketDto': MyfamilysrlNikitaSdk.TicketDto.constructFromObject(payload), MF_TENANT_ID
            };
        TicketApi.apiTicketIdCustomerCustomerIdPut(id, customerId, opts)
        .then((response) => {
            res(response);
        })
        .catch((error) => {
            rej(error);
        })
        .finally(utils.actions.unloadRes);
    }),

    apiMessagesCustomerCustomerIdGet: async ({dispatch}, customerId) => new Promise((res, rej) => {
        utils.actions.loadRes();
        MessagesApi.apiMessagesCustomerCustomerIdGet(customerId, {MF_TENANT_ID})
        .then((response) => {
            dispatch('setMessages', response);
            res(response);
        })
        .catch((error) => {
            rej(error);
        })
        .finally(utils.actions.unloadRes);
    }),
    apiMessagesCustomerCustomerIdPost: async ({dispatch}, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let customerId = payload.entityId,
            opts = {
                'messageDto': MyfamilysrlNikitaSdk.MessageDto.constructFromObject(payload), MF_TENANT_ID
            };
        MessagesApi.apiMessagesCustomerCustomerIdPost(customerId, opts)
        .then((response) => {
            dispatch('apiMessagesCustomerCustomerIdGet', customerId);
            res(response);
        })
        .catch((error) => {
            rej(error);
        })
        .finally(utils.actions.unloadRes);
    }),
    apiMessagesIdCustomerCustomerIdDelete: async ({dispatch}, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let id = payload.id,
            customerId = payload.entityId,
            opts = {
                'messageDto': MyfamilysrlNikitaSdk.MessageDto.constructFromObject(payload),
                MF_TENANT_ID
            };
        MessagesApi.apiMessagesIdCustomerCustomerIdDelete(id, customerId, opts)
        .then((response) => {
            dispatch('apiMessagesCustomerCustomerIdGet', customerId);
            res(response);
        })
        .catch((error) => {
            rej(error);
        })
        .finally(utils.actions.unloadRes);
    }),

    apiReleaseNoteGet: async ({dispatch}) => new Promise((res, rej) => {
        utils.actions.loadRes();
        ReleaseNoteApi.apiReleaseNoteGet({MF_TENANT_ID})
        .then((result) => {
            result = result.sort((a, b) => a.code < b.code ? 1 : -1).map(x => ({...x, description: (JSON.parse(x?.description ?? "{}"))[localStorage.getItem('lang')]}));
            let readed = localStorage.getItem('releaseNotes');
            setTimeout(() => {
                // debugger
                let collection = !!readed && readed != 'undefined' ? JSON.parse(readed) : [];
                if(result.find(x => !collection.find(code => code == x.code))){
                    dispatch("setShowRelease", true);
                }
            }, 3000);
            dispatch('setReleaseNotes', result);
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),


    showReleaseNotes({dispatch}){
        dispatch("setShowRelease", true);
    },

    dismissRelease: function({dispatch}){
        localStorage.setItem('releaseNotes', JSON.stringify(this.state?.notifications?.releaseNotes?.reduce((a, b) => { a.push(b.code); return a; }, [])));
        dispatch("setShowRelease", false);
    },

}

const getters = {
    tagCollection: state => {
        return state.tags.map(item => ({ text: `${item.key} - ${item.value}`, value: item.id }))
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
