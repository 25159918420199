// Pathify
import { make } from 'vuex-pathify'
import utils from './utils';
const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const CurrencyApi = new MyfamilysrlNikitaSdk.CurrencyApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

// Data
const state = {
    currencies: []
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),

  initCurrenciesCollection: async (dispatch, force) => new Promise((res, rej) => {
    if (state.currencies.length === 0 || !!force) {
      actions.apiCurrencyGet(dispatch, force)
          .then((response) => res(response))
          .catch((error) => rej(error));
    } else {
      res(state.currencies);
    }
  }),

  apiCurrencyGet: async ({ dispatch }, force) => new Promise((res, rej) => {
    !!force && utils.actions.loadRes();
    CurrencyApi.apiCurrencyGet({MF_TENANT_ID})
    .then((result) => {
      dispatch('setCurrencies', result);
      res(result);
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      !!force && utils.actions.unloadRes();
    });
  }),

}

const getters = {
  dropdownCurrencies: state => {
    return state.currencies.map(item => ({text: `${item.symbol} - ${item.name}`, value: item.id}));
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
