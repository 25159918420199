// Pathify
import { Store } from 'vuex';
import {make} from 'vuex-pathify';
import utils from './utils';
const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const ItemApi = new MyfamilysrlNikitaSdk.ItemApi();
const LanguageApi = new MyfamilysrlNikitaSdk.LanguageApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

const state = {
    languages : []
};

const mutations = make.mutations(state);

const actions = {
    ...make.actions(state),


    apiLanguageGet: async ({dispatch}) => new Promise((res, rej) => {
        utils.actions.loadRes();
        LanguageApi.apiLanguageGet({MF_TENANT_ID})
        .then((result) => {
            dispatch("setLanguages", result);
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }), 


    apiItemIdWithTranslationsGet: async ({dispatch}, id) => new Promise((res, rej) => {
        utils.actions.loadRes();
        ItemApi.apiItemIdWithTranslationsGet(id, {MF_TENANT_ID})
        .then((result) => {
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }), 

    // obj['id']
    // obj['langTag']
    // obj['itemId']
    // obj['translationValue']
    apiItemTranslationPatch: async ({dispatch}, obj) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let opts = {
            itemDescriptionI18N: MyfamilysrlNikitaSdk.ItemDescriptionI18N.constructFromObject(obj),
            MF_TENANT_ID
        };
        ItemApi.apiItemTranslationPatch(opts)
        .then((result) => {
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }), 
}

const getters = {
    languagesDropdown: state => state.languages.map(item => ({text: item.langTag + " - " + item.langTagDescription, value: item.langTag})),
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
