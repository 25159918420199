// Pathify
import { make } from 'vuex-pathify'
import utils from './utils';
const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const CustomerLocationApi = new MyfamilysrlNikitaSdk.CustomerLocationsApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

// Data
const state = {
    customerLocations: []
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),

  customerLocationsGet: async ({ dispatch }, id = null) => new Promise((res, rej) => {
    utils.actions.loadRes();
    const opts = {
      customerId: customerId,
      MF_TENANT_ID
    }
    CustomerLocationApi.apiCustomerLocationsGet(opts)
    .then((result) => {
      dispatch('setCustomerLocations', result);
      res(result);
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  customerLocationsPost: async ({ dispatch }, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      'customerLocation': MyfamilysrlNikitaSdk.CustomerLocation.constructFromObject({
        ...payload, 
        city: payload.city?.toUpperCase() ?? payload.city,
        address: payload.address?.toUpperCase() ?? payload.address
      }),
      MF_TENANT_ID
    };
    CustomerLocationApi.apiCustomerLocationsPost(opts)
    .then(() => {
      dispatch('app/showSuccess', 'completed', { root: true });
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  customerLocationsPut: async ({ dispatch }, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      'customerLocation': MyfamilysrlNikitaSdk.CustomerLocation.constructFromObject({
        ...payload, 
        city: payload.city?.toUpperCase() ?? payload.city,
        address: payload.address?.toUpperCase() ?? payload.address
      }),
      MF_TENANT_ID
    };
    let stringId = `${payload.id}`;
    CustomerLocationApi.apiCustomerLocationsIdPut(stringId, opts)
    .then(() => {
      dispatch('app/showSuccess', 'completed', { root: true });
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  customerLocationsDelete: async ({ dispatch }, id) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let stringId = `${id}`;
    CustomerLocationApi.apiCustomerLocationsIdDelete(stringId, {MF_TENANT_ID})
    .then(() => {
      dispatch('app/showSuccess', 'completed', { root: true });
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  apiCustomerLocationsExportMetodoPost: ({dispatch}, customerIdCollection) => new Promise((res, rej) => {
    if(!customerIdCollection.length) rej();

    utils.actions.loadRes();
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) { 
          dispatch('app/showSuccess', 'completed', { root: true });
          res(xhr.response); 
        }
    };
    xhr.open("POST", `${process.env.VUE_APP_BACKEND_BASEPATH}/api/CustomerLocations/export/metodo`, true);
    xhr.setRequestHeader('Content-Type', 'application/json'); // 'text/plain'
    xhr.setRequestHeader('Authorization', MyfamilysrlNikitaSdk.ApiClient.instance.defaultHeaders["Authorization"]);
    xhr.setRequestHeader('Accept', 'text/plain');
    xhr.setRequestHeader('Mf-Tenant-Id',MF_TENANT_ID);
    xhr.responseType = 'arraybuffer';
    
    xhr.send(JSON.stringify({customer : customerIdCollection}));
    utils.actions.unloadRes();
  }),

}

const getters = {
  dropdownCustomerLocations: state => {
    return state.customerLocations.map(item => ({text: item.name + " - " + item.address, value: item.id}))
  },
  customerLocationInfo: state => (id) => {
    let cl = state.customerLocations.find(el => el.id == id);
    return `${cl?.name ?? '-'} - ${cl?.address ?? '-'}`;
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
