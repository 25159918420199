// Imports
import Vue from 'vue'
import Router from 'vue-router'
import { trailingSlash } from '@/util/helpers'
import { layout, route, } from '@/util/routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (to.hash) resolve({ 
          // selector: to.hash 
          el: to.hash,
          behavior: 'smooth',
        });
        if (savedPosition) resolve(savedPosition);
        resolve({ left: 0, top: 0 });
      }, 1000)
    })
  },
  routes: [
    layout('Default', [

      route('UserProfile', null, 'components/profile'),
      route('Dashboard', null, 'dashboard'),
      route('Report', null, 'report'),
      
      route('Tables/Order'),
      route('Tables/Collection', null, 'collections'),
      route('Tables/Item', null, 'items'),
      route('Tables/Brand', null, 'brands'),
      route('Tables/Kit', null, 'kits'),
      route('Tables/Group', null, 'groups'),
      route('Tables/Order', null, 'orders'),
      route('Tables/EDI', null, 'edi'),
      route('Tables/OrderRowContent', null, 'order-row-content'),
      route('Tables/Customer', null, 'customers'),
      route('Tables/Agent', null, 'agents'),
      route('Tables/Vats', null, 'vats'),
      route('Tables/CustomerMonitoring', null, 'customer-monitoring'),
      route('Tables/Budget', null, 'budget'),
      route('Tables/PaymentMethods', null, 'payment-methods'),      
      route('Tables/PaymentTerms', null, 'payment-terms'),
  
      route('Pages/Item', null, 'items/:itemId', true),
      route('Pages/Kit', null, 'kits/:kitId', true),
      route('Pages/Order', null, 'orders/:orderId', true),
      route('Pages/Order', null, 'orders/historical/:orderId', true),
      route('Pages/Agent', null, 'agents/:agentId', true),
      route('Pages/Customer', null, 'customers/:customerId', true),
      route('Pages/PriceCatalog', null, 'load-price-catalog'),      
      route('Pages/NewCustomer', null, 'addCustomer'),

      route('Tables/TntLabels', null, 'tnt'),
      route('Pages/TntShipment', null, 'tnt/shipment', true),
      
    ]),
  ],
})

/*router.beforeEach((to, from, next) => {
  return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
})*/

export default router
