// Pathify
import { dispatch, make } from 'vuex-pathify';
import utils from './utils';
const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const AuthUserApi = new MyfamilysrlNikitaSdk.AuthUserApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

// Data
const state = {
  authUsers: [],
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),

  initAuthUserCollection: async ({ dispatch }, force) => {
    if (state.authUsers.length == 0 || !!force) {
      dispatch('apiAuthUserGet', force);
    }
  },

  apiAuthUserGet: async ({ dispatch }, force = false) => new Promise((res, rej) => {
    !!force && utils.actions.loadRes();
    AuthUserApi.apiAuthUserGet({ MF_TENANT_ID })
    .then((result) => {
      dispatch('setAuthUsers', result.filter(x => x.tenantUuid == localStorage.getItem('tenantUuid')));
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej();
    })
    .finally(() => {
      !!force && utils.actions.unloadRes();
    });
  }),

}

const getters = {
  dropdownUsers: state => {
    return state.authUsers?.map(item => ({ text: `${item.username} - ${item.givenName} ${item.familyName}`, value: item.uuid }));
  },
  formattedName: state => (uuid) => {
    let user = state.authUsers?.find(el => el.uuid == uuid);
    return !!user
      ? `${user?.givenName ?? ''} ${user?.familyName ?? ''}` 
      : 'n.a.';
  } 
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
