// Pathify
import {make} from 'vuex-pathify';
import utils from './utils';

const IamSdk = require('@myfamilysrl/iam_sdk');
const IamTraitApi = new IamSdk.IamTraitApi();
const IamUserApi = new IamSdk.IamUserApi();
const IamAttributeApi = new IamSdk.IamAttributeApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

const state = {
    iamTraits: [],
}

const mutations = make.mutations(state)

const actions = {
    ...make.actions(state),

    apiGetTraits: async ({dispatch}) => new Promise((res, rej) => {
        utils.actions.loadRes();
        IamTraitApi.apiIamTraitGet({MF_TENANT_ID})
            .then((result) => {
                dispatch('setIamTraits', result);
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiIamTraitIdUsersCustomValueGet: async ({dispatch}, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        const iamTraitId = payload?.iamTrait?.id || 0;
        const customValue = payload?.customValue || "";
        IamTraitApi.apiIamTraitIdUsersCustomValueGet(iamTraitId, customValue, {MF_TENANT_ID})
            .then((result) => {
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiIamUserPost: async ({dispatch}, iamUser) => new Promise((res, rej) => {
        utils.actions.loadRes();
        const opts = {
            'iamUserPostRequestDto': IamSdk.IamUserPostRequestDto.constructFromObject(iamUser),
            MF_TENANT_ID
        }
        IamUserApi.apiIamUserPost(opts)
            .then((result) => {
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiIamUserUuidTraitsTraitIdPost: async ({dispatch}, payload) => new Promise((res, rej) => {
        utils.actions.loadRes();
        const uuid = payload.uuid || "";
        const traitId = payload.traitId || 0;
        const opts = {
            'setTraitOnUserDto': IamSdk.SetTraitOnUserDto.constructFromObject({
                customAttributeValues: payload.customAttributeValues
            }),
            MF_TENANT_ID
        };
        IamUserApi.apiIamUserUuidTraitsTraitIdPost(uuid, traitId, opts)
            .then((result) => {
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),
    
    apiIamUserUuidSendTechlaWelcomeEmailPost: async ({dispatch}, uuid) => new Promise((res, rej) => {
        utils.actions.loadRes();
        IamUserApi.apiIamUserUuidSendTechlaWelcomeEmailPost(uuid, {MF_TENANT_ID})
            .then((result) => {
                dispatch('app/showSuccess', `Well done!`, {root: true});
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiIamUserUuidSendB2BEmailPost: async ({dispatch}, uuid) => new Promise((res, rej) => {
        utils.actions.loadRes();
        IamUserApi.apiIamUserUuidSendB2BEmailPost(uuid, {MF_TENANT_ID})
            .then((result) => {
                dispatch('app/showSuccess', `Well done!`, {root: true});
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),
            
    apiIamUserUuidPrintCredentialsPost: async ({dispatch}, uuid) => new Promise((res, rej) => {
        utils.actions.loadRes();
        IamUserApi.apiIamUserUuidPrintCredentialsPost(uuid, {MF_TENANT_ID})
            .then((result) => {
                dispatch('app/showSuccess', `Well done!`, {root: true});
                res(result);
            })
            .catch((error) => {
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),

    apiIamAttributeGet: async ({dispatch}) => new Promise((res, rej) => {
        utils.actions.loadRes();
        IamAttributeApi.apiIamAttributeGet({MF_TENANT_ID})
        .then((result) => {
            dispatch('app/showSuccess', `Well done!`, {root: true});
            res(result);
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),
    apiIamAttributeIdGet: async ({dispatch}, id) => new Promise((res, rej) => {

    }),
    apiIamAttributeIdDelete: async ({dispatch}, id) => new Promise((res, rej) => {

    }),
    apiIamAttributePost: async ({dispatch}) => new Promise((res, rej) => {

    }),
    apiIamAttributeIdPut: async ({dispatch}) => new Promise((res, rej) => {

    }),

    
}

const getters = {}

export default {
    namespaced: true, state, mutations, actions, getters,
}
