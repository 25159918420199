// Pathify
import { make } from 'vuex-pathify'
import utils from './utils';
const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const CustomerIbansApi = new MyfamilysrlNikitaSdk.CustomerIbansApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

// Data
const state = {}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),

  apiCustomerIbansPost: async ({ dispatch }, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      'customerIban': MyfamilysrlNikitaSdk.CustomerIban.constructFromObject(payload),
      MF_TENANT_ID
    };
    CustomerIbansApi.apiCustomerIbansPost(opts)
    .then(() => {
      dispatch('app/showSuccess', 'completed', { root: true });
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  apiCustomerIbansIdPut: async ({ dispatch }, param) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      'customerIban': MyfamilysrlNikitaSdk.CustomerIban.constructFromObject(param),
      MF_TENANT_ID
    };
    CustomerIbansApi.apiCustomerIbansIdPut(param.id, opts)
    .then(() => {
      dispatch('app/showSuccess', 'completed', { root: true });
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  apiCustomerIbansIdDelete: async ({ dispatch }, id) => new Promise((res, rej) => {
    utils.actions.loadRes();
    CustomerIbansApi.apiCustomerIbansIdDelete(id, {MF_TENANT_ID})
    .then(() => {
      dispatch('app/showSuccess', 'completed', { root: true });
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
