// Pathify
import { make } from "vuex-pathify";
import utils from "./utils";
const NikitaSdk = require("@myfamilysrl/nikita_sdk");
const CustomerBudgetObjectivesApi = new NikitaSdk.CustomerBudgetObjectivesApi;
const MF_TENANT_ID = localStorage.getItem('tenantUuid');

// Data
const state = {
  customerCollection: [],
  countryCollection: [],
  viewFiltered: false,
  filters: {
    showChildren: false,
    geonameCountryCode: [],
    salesChannels: [],
    salesSectors: [],
    customerPurchaseCategoryId: [],
    priceCatalogId: [],
    customerStatisticalTag: [],
    tag: [],
    parentCode: null
  },
};

const mutations = make.mutations(state);

const actions = {
    ...make.actions(state),

    switchView: ({dispatch}) => {
        state.viewFiltered = !state.viewFiltered;
    },

    apiCustomerBudgetObjectivesFilteredCountryDetailsPost: async ({dispatch}) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let opts = { 
            customerBudgetFilters: state.filters, 
            MF_TENANT_ID, 
            showChildren: state.showChildren 
        };
        CustomerBudgetObjectivesApi.apiCustomerBudgetObjectivesFilteredCountryDetailsPost(opts)
        .then((response) => {
            dispatch("setCountryCollection", response);
            dispatch('app/showSuccess', 'completed', { root: true });
            res(response); 
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => { 
            utils.actions.unloadRes();
        });
    }),

    apiCustomerBudgetObjectivesFilteredPost: async ({dispatch}) => new Promise((res, rej) => {
        utils.actions.loadRes();
        let opts = { 
            customerBudgetFilters: state.filters, 
            MF_TENANT_ID, 
            showChildren: state.showChildren 
        };
        CustomerBudgetObjectivesApi.apiCustomerBudgetObjectivesFilteredPost(opts)
        .then((response) => {
            let mapped = response.map(el => ({
                ...el,
                sumBudget: el.budgetObjectives.reduce((a, b) => {
                    a += +b.budgetValueObjective ?? 0;
                    return a;
                }, 0)
            }))
            dispatch("setCustomerCollection", mapped);
            dispatch('app/showSuccess', 'completed', { root: true });
            res(response); 
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => { 
            utils.actions.unloadRes();
        });
    }),

    apiCustomerBudgetObjectivesIdDelete: async ({dispatch}, id) => new Promise((res, rej) => {
        utils.actions.loadRes();
        CustomerBudgetObjectivesApi.apiCustomerBudgetObjectivesIdDelete(id, {MF_TENANT_ID})
        .then((response) => {
            dispatch("apiCustomerBudgetObjectivesFilteredPost");
            dispatch('app/showSuccess', 'completed', { root: true });
            res(response); 
        })
        .catch((error) => {
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => { 
            utils.actions.unloadRes();
        });
    }),
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
