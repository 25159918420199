// Pathify
import { make } from 'vuex-pathify'
import utils from './utils';
const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const FamilyApi = new MyfamilysrlNikitaSdk.FamilyApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

// Data
const state = {
    families: []
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),

  initFamilyCollection: async (dispatch, force) => new Promise((res, rej) => {
    if (state.families.length === 0 || !!force) {
      actions.familyGet(dispatch, force)
          .then((response) => res(response))
          .catch((error) => rej(error));
    } else {
      res(state.families);
    }
  }),

  familyGet: async ({ dispatch }, force) => new Promise((res, rej) => {
    !!force && utils.actions.loadRes();
    FamilyApi.apiFamilyGet({MF_TENANT_ID})
    .then((result) => {
      dispatch('setFamilies', result);
      res(result);
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      !!force && utils.actions.unloadRes();
    });
  }),

  familyPost: async ({ dispatch }, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      'family': MyfamilysrlNikitaSdk.Family.constructFromObject(payload),
      MF_TENANT_ID
    };
    FamilyApi.apiFamilyPost(opts)
    .then(() => {
      dispatch('familyGet');
      dispatch('app/showSuccess', 'completed', { root: true });
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  familyUpdate: async ({ dispatch }, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      'family': MyfamilysrlNikitaSdk.Family.constructFromObject(payload),
      MF_TENANT_ID
    };
    let stringId = `${payload.id}`;
    FamilyApi.apiFamilyIdPut(stringId, opts)
    .then(() => {
      dispatch('familyGet');
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  familyDelete: async ({ dispatch }, id) => new Promise((res, rej) => {
    let stringId = `${id}`;
    FamilyApi.apiFamilyIdDelete(stringId, {MF_TENANT_ID})
    .then(() => {
      dispatch('familyGet');
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

}

const getters = {
  dropdownFamilies: state => {
    return state.families
      .sort((a, b) => a.description.localeCompare(b.description))
      .map(item => ({ text: `${item.description}`, value: item.id }));
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
