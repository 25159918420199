// Pathify
import { reject } from 'lodash';
import { dispatch, make } from 'vuex-pathify'
import utils from './utils';
const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const CommercialSpecialistApi = new MyfamilysrlNikitaSdk.CommercialSpecialistApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }


// Data
const state = {
  commercialSpecialists: [],
  loadingCommercialSpecialists: false
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),

  initCommercialSpecialistCollection: async ({ dispatch }, force) => {
    if (state.commercialSpecialists.length == 0 || !!force) {
      dispatch('commercialSpecialistGet', force);
    }
  },

  commercialSpecialistGet: async ({ dispatch }, force) => new Promise((res, rej) => {
    !!force && utils.actions.loadRes();
    dispatch("setLoadingCommercialSpecialists", true);
    CommercialSpecialistApi.apiCommercialSpecialistGet({MF_TENANT_ID})
    .then((result) => {
      dispatch('setCommercialSpecialists', result);
      dispatch("setLoadingCommercialSpecialists", false);
      res(result);
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      !!force && utils.actions.unloadRes();
    });
  }),

  commercialSpecialistPost: async ({ dispatch }, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      'commercialSpecialist': MyfamilysrlNikitaSdk.CommercialSpecialist.constructFromObject(payload),
      MF_TENANT_ID
    };
    CommercialSpecialistApi.apiCommercialSpecialistPost(opts)
    .then((result) => {
      dispatch('commercialSpecialistGet')
      dispatch('app/showSuccess', 'completed', { root: true });
      res(result);
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  commercialSpecialistUpdate: async ({ dispatch }, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      'commercialSpecialist': MyfamilysrlNikitaSdk.CommercialSpecialist.constructFromObject(payload),
      MF_TENANT_ID
    };
    let stringId = `${payload.id}`;
    CommercialSpecialistApi.apiCommercialSpecialistIdPut(stringId, opts)
    .then((result) => {
      dispatch('commercialSpecialistGet')
      dispatch('app/showSuccess', 'completed', { root: true });
      res(result);
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  commercialSpecialistDelete: async ({ dispatch }, id) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let stringId = `${id}`;
    CommercialSpecialistApi.apiCommercialSpecialistIdDelete(stringId, {MF_TENANT_ID})
    .then(() => {
      dispatch('commercialSpecialistGet')
      dispatch('app/showSuccess', 'completed', { root: true });
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

}

const getters = {
  dropdownCommercialSpecialists: state => state.commercialSpecialists
    .filter(cs => !!cs.authUserCode)
    .filter(cs => cs.tenantUuid == MF_TENANT_ID)
    .sort((a, b) => a.name < b.name? -1 : 1)
    .map(item => ({ text: item.name, value: item.id })),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
