// Vue
import Vue from 'vue'
import Vuex from 'vuex'
import pathify from '@/plugins/vuex-pathify'

// Modules
import * as modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  plugins: [
    pathify.plugin,
  ],
  state: {
    tempCollection : [
      { code : '123', username : 'carlo', password : 'sales', email : 'carlo@gmail.com', auth_profile_type : 'sales', role: 'Capo Agente', company: 'Myfamily', name: 'Carlo', lastname: 'Macchi', address: 'strada solero 1', city: 'Valenza', country: 'Italia', PostalCode: '15048' },
      { code : '456', username : 'ale', password : 'admin', email : 'ale@gmail.com', auth_profile_type : 'admin' },
      { code : '789', username : 'clara', password : 'sales', email : 'clara@gmail.com', auth_profile_type : 'comm' },
      { code : '789', username : 'amico', password : 'sales', email : 'clara@gmail.com', auth_profile_type : 'agent' }
    ],
    myself : { code : '123', username : 'carlo', password : 'sales', email : 'carlo@gmail.com', auth_profile_type : 'sales',role: 'Capo Agente', company: 'Myfamily', name: 'Carlo', lastname: 'Macchi', address: 'strada solero 1', city: 'Valenza', country: 'Italia', PostalCode: '15048' },
    logged : true,
    modalService : {
      showNewOrderModal : false,
      showUpdateOrderModal : false,
      showOrderModal : false,
    }
  }
})

const mutations = {
  setAllCustomers: (state, customers) => (state.customers = customers),
  removeCustomer: (state, id) =>
      (state.customers = state.customers.filter(customer => customer.ID !== 
  id)),
  toggleDialog: (state) => (state.dialogDelete = !state.dialogDelete)    
}

store.subscribe(mutation => {
  if (!mutation.type.startsWith('user/')) return
  store.dispatch('user/update', mutation)
});

store.dispatch('app/init');

export default store

export const ROOT_DISPATCH = Object.freeze({ root: true })



