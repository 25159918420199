// Pathify
import { make } from 'vuex-pathify'
import utils from './utils';
const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const GroupApi = new MyfamilysrlNikitaSdk.ItemGroupApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

// Data
const state = {
    groups: []
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  
  initGroupCollection: async ({ dispatch }, force) => {
    if (state.groups.length === 0 || !!force) {
      dispatch('itemGroupGet', force);
    }
  },

  itemGroupGet: async ({ dispatch }, force) => new Promise((res, rej) => {
    !!force && utils.actions.loadRes();
    GroupApi.apiItemGroupGet({MF_TENANT_ID})
    .then((result) => {
      dispatch('setGroups', result.filter(x => !x.deleted));
      res(result.filter(x => !x.deleted));
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      !!force && utils.actions.unloadRes();
    });
  }),

  itemGroupPost: async ({ dispatch }, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      'itemGroup': MyfamilysrlNikitaSdk.ItemGroup.constructFromObject(payload),
      MF_TENANT_ID
    };
    GroupApi.apiItemGroupPost(opts)
    .then(() => {
      dispatch('itemGroupGet');
      dispatch('app/showSuccess', 'completed', { root: true });
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  itemGroupIdPut: async ({ dispatch }, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      'itemGroup': MyfamilysrlNikitaSdk.ItemGroup.constructFromObject(payload),
      MF_TENANT_ID
    };
    let stringId = `${payload.id}`;
    GroupApi.apiItemGroupIdPut(stringId, opts)
    .then(() => {
      dispatch('itemGroupGet');
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  itemGroupIdDelete: async ({ dispatch }, id) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let stringId = `${id}`;
    GroupApi.apiItemGroupIdDelete(stringId, {MF_TENANT_ID})
    .then(() => {
      dispatch('itemGroupGet');
      res()
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

}

const getters = {
  dropdownGroups: state => {
    // let groupReduce = state.groups.reduce((acc, el) => {
    //   acc = acc || {};
    //   acc[el.code] = acc[el.code] || [];
    //   acc[el.code].push(el.id);
    //   return acc;
    // }, {});
    // return Object.keys(groupReduce).sort((a, b) => a.localeCompare(b)).map((el) => ({text: el, value: groupReduce[el]}));
    return state.groups.map((x) => ({
      text: `${x.familyId} - ${x.code} [${x.id}]`,
      value: x.id,
      familyId: x.familyId
    })).sort((a, b) => a.text.localeCompare(b.text))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
