// Pathify
import { make } from "vuex-pathify";
import utils from "./utils";
import customerBudget from "./customerBudget";
const NikitaSdk = require("@myfamilysrl/nikita_sdk");
const CustomerMonitoringApi = new NikitaSdk.MonitoringDataApi();
const CustomerBudgetObjectivesApi = new NikitaSdk.CustomerBudgetObjectivesApi();
const OrderStatisticalTagApi = new NikitaSdk.OrderStatisticalTagApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

// Data
const state = {
  filters: {
    customerId: null,
    parentCode: null,
    agentId: null,
    supervisorAgentId: null,
    chiefAgentId: null,
    commercialSpecialistId: null,
    geonameCountryCode: null,
    geonameRegionCode: null,
    geonameProvinceCode: null,
    lastOrderDate: null,
    customerStatisticalTagId: null,
    customerPurchaseCategoryId: null,
    orderStatisticalTagId: null,
    isLoanForUse: null,
    cutoffLastOrderDate: null
  },
  cutoffDate: null,
  customerMonitoringData: [],
  total: {},
  endDateYTD: null,
  daysYTD: 0,
  orderStatisticalTags: []
};

const mutations = make.mutations(state);

const actions = {
  ...make.actions(state),

  initBudgetMonitoringPost: async (dispatch, force) =>
    new Promise((res, rej) => {
      if (state.customerMonitoringData.length === 0 || !!force) {
        actions
          .budgetMonitoringPost(dispatch)
          .then((response) => {
            res(response);
          })
          .catch((error) => rej(error));
      } else {
        res(state.customerMonitoringData);
      }
    }),

  budgetMonitoringPost: async ({ dispatch }) =>
    new Promise((res, rej) => {
      utils.actions.loadRes();
      console.log(state.filters);
      let opts = {
        cutoffDate: state.cutoffDate,
        customerMonitoringDataFilters:
          // NikitaSdk.CustomerMonitoringDataFilters.constructFromObject(
            state.filters,
          // ),
          MF_TENANT_ID
      };
      console.log(opts);
      CustomerMonitoringApi.apiMonitoringDataByCustomerPost(opts)
        .then((result) => {
          dispatch('app/showSuccess', 'completed', { root: true });
          dispatch("setCustomerMonitoringData", result.monitoringData);
          dispatch("setTotal", result.total);
          dispatch("setEndDateYTD", result.endDateCY);
          dispatch("setDaysYTD", result.daysCY);
          res(result);
        })
        .catch((error) => {
          dispatch(
            "app/showError",
            `${`${error.error || error}`.split("at")[0] || "Error! Try later"}`,
            { root: true }
          );
          rej(error);
        })
        .finally(() => {
          utils.actions.unloadRes();
        });
    }),

  apiListExport: ({ dispatch }) =>
    new Promise((res, rej) => {
      utils.actions.loadRes();
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          utils.actions.unloadRes();
          if (xhr.status === 200) {
            dispatch('app/showSuccess', 'completed', { root: true });
            res(xhr.response);
          } else {
            const error = `${xhr.status} - ${xhr.statusText}`;
            dispatch("app/showError", `${`${error.error || error}`.split("at")[0] || "Error! Try later"}`, { root: true });
            rej(error);
          }
        }
      };
      xhr.open(
        "POST",
        `${process.env.VUE_APP_REPORTING_BASEPATH}/api/CustomerMonitoring`,
        true
      );
      xhr.setRequestHeader("Content-Type", "application/json"); // 'text/plain'
      xhr.setRequestHeader("Authorization", NikitaSdk.ApiClient.instance.defaultHeaders["Authorization"]);
      xhr.setRequestHeader("Accept", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      xhr.setRequestHeader("MF-TENANT-ID", MF_TENANT_ID);
      xhr.responseType = "arraybuffer";
      xhr.send(JSON.stringify(state.filters));
    }),

    apiCustomerBudgetObjectivesAdjustCustomerObjectivePut: async ({dispatch}, payload) => new Promise((res, rej) => {
      utils.actions.loadRes();
      let opts = { customerBudgetObjective: NikitaSdk.CustomerBudgetObjective.constructFromObject(payload), MF_TENANT_ID };
      CustomerBudgetObjectivesApi.apiCustomerBudgetObjectivesAdjustCustomerObjectivePut(opts).then((response) => { 
        dispatch('customerBudget/apiCustomerBudgetObjectivesFilteredPost', null, {root:true});
        dispatch('app/showSuccess', 'completed', { root: true });
        res(response); 
      })
      .catch((error) => {
        dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
        rej(error);
      })
      .finally(() => { utils.actions.unloadRes() });
    }),

    // customerCollection, orderStatisticalTagId, budgetValueObjective
    // customerId, orderStatisticalTagId, budgetQuantityObjective, budgetValueObjective
    apiCustomerCollectionBudgetObjectivesPut: async ({dispatch}, payload) => new Promise((res, rej) => {
      utils.actions.loadRes();

      Promise.all(payload.customerCollection.map(c => {
        let opts = { customerBudgetObjective: NikitaSdk.CustomerBudgetObjective.constructFromObject({
          customerId: c.customerId, 
          orderStatisticalTagId: payload.orderStatisticalTagId, 
          budgetValueObjective: payload.budgetValueObjective, 
        }), MF_TENANT_ID };
        return CustomerBudgetObjectivesApi.apiCustomerBudgetObjectivesAdjustCustomerObjectivePut(opts);
      }))
      .then(response => {
        dispatch('customerBudget/apiCustomerBudgetObjectivesFilteredPost', null, {root:true});
        dispatch('app/showSuccess', 'completed', { root: true });
        res(response);
      })
      .catch((error) => {
        dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
        rej(error);
      })
      .finally(() => {  
        utils.actions.unloadRes() 
      });
    }),

    apiOrderStatisticalTagGet: async ({dispatch}, payload) => new Promise((res, rej) => {
      utils.actions.loadRes();
      OrderStatisticalTagApi.apiOrderStatisticalTagGet().then((response) => { 
        dispatch("setOrderStatisticalTags", response);
        res(response); 
      })
      .catch((error) => {
        dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
        rej(error);
      })
      .finally(() => { utils.actions.unloadRes() });
    }),
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
