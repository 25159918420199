// Pathify
import {make} from 'vuex-pathify';
import utils from './utils';

const BcSyncSdk = require('@myfamilysrl/bcsync_sdk');
const OrderSyncApi = new BcSyncSdk.OrderSyncApi();
const CustomerSyncApi = new BcSyncSdk.CustomerSyncApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

const state = {}

const mutations = make.mutations(state)

const actions = {
    ...make.actions(state),

    // initTicketCollection: async ({ dispatch }, force) => {
    //     if (state.notifications.length === 0 || !!force) {
    //         dispatch('apiCustomersIdTagsGet', force);
    //     }
    // },

    // HANDLE TICKETS
    apiOrderSyncIdPost: async ({dispatch}, orderId) => new Promise((res, rej) => {
        utils.actions.loadRes();
        OrderSyncApi.apiOrderSyncIdPost(orderId, {MF_TENANT_ID})
            .then((result) => {
                dispatch('app/showSuccess', "Synchronization finished! Check the result on Business Central and report any difference", {root: true});
                res(result);
            })
            .catch((error) => {
                error.error.message = `${!!error.body?.title ? error.body.title : ''} ${!!error.body?.detail ? error.body.detail : ''}`;
                error.body = null;
                dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
                rej(error);
            })
            .finally(() => {
                utils.actions.unloadRes();
            });
    }),
    
    apiBCCustomerSyncIdPost: async ({dispatch}, customerId) => new Promise((res, rej) => {
        utils.actions.loadRes();
        CustomerSyncApi.apiCustomerSyncIdPost(customerId, {MF_TENANT_ID})
        .then((result) => {
            dispatch('app/showSuccess', "Synchronization finished! Check the result on Business Central and report any difference", {root: true});
            res(result);
        })
        .catch((error) => {
            error.error.message = `${!!error.body?.title ? error.body.title : ''} ${!!error.body?.detail ? error.body.detail : ''}`;
            error.body = null;
            dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
            rej(error);
        })
        .finally(() => {
            utils.actions.unloadRes();
        });
    }),
}

const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
