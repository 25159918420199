// Pathify
import { make } from 'vuex-pathify'
import utils from './utils';
const MyfamilysrlNikitaSdk = require('@myfamilysrl/nikita_sdk');
const CollectionApi = new MyfamilysrlNikitaSdk.ItemCollectionApi();
const MF_TENANT_ID = localStorage.getItem('tenantUuid');
// { MF_TENANT_ID }

// Data
const state = {
    collections: []
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  
  initCollectionCollection: async ({ dispatch }, force) => {
    if (state.collections.length === 0 || !!force) {
      dispatch('apiItemCollectionGet', force);
    }
  },

  apiItemCollectionGet: async ({ dispatch }, force) => new Promise((res, rej) => {
    !!force && utils.actions.loadRes();
    CollectionApi.apiItemCollectionGet({ MF_TENANT_ID })
    .then((result) => {
      dispatch('setCollections', result.filter(x => !x.deleted));
      res(result.filter(x => !x.deleted));
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      !!force && utils.actions.unloadRes();
    });
  }),

  apiItemCollectionPost: async ({ dispatch }, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      'itemCollection': MyfamilysrlNikitaSdk.ItemCollection.constructFromObject(payload),
      MF_TENANT_ID
    };
    CollectionApi.apiItemCollectionPost(opts)
    .then(() => {
      dispatch('apiItemCollectionGet', true)
      dispatch('app/showSuccess', 'completed', { root: true });
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  apiItemCollectionIdPut: async ({ dispatch }, payload) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let opts = {
      'itemCollection': MyfamilysrlNikitaSdk.ItemCollection.constructFromObject(payload),
      MF_TENANT_ID
    };
    let stringId = `${payload.id}`;
    CollectionApi.apiItemCollectionIdPut(stringId, opts)
    .then(() => {
      dispatch('app/showSuccess', 'completed', { root: true });
      dispatch('apiItemCollectionGet', true);
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),

  apiItemCollectionIdDelete: async ({ dispatch }, id) => new Promise((res, rej) => {
    utils.actions.loadRes();
    let stringId = `${id}`;
    CollectionApi.apiItemCollectionIdDelete(stringId, {MF_TENANT_ID})
    .then(() => {
      dispatch('app/showSuccess', 'completed', { root: true });
      dispatch('apiItemCollectionGet', true);
      res();
    })
    .catch((error) => {
      dispatch('app/showError', `${`${error.error || error}`.split(' at ')[0] || 'Error! Try later' }`, {root: true});
      rej(error);
    })
    .finally(() => {
      utils.actions.unloadRes();
    });
  }),
  
}

const getters = {
  dropdownCollections: state => {
    // let collectionReduce = state.collections.reduce((acc, el) => {
    //   acc = acc || {};
    //   acc[el.code] = acc[el.code] || [];
    //   acc[el.code].push(el.id);
    //   return acc;
    // }, {});
    // return Object.keys(collectionReduce).sort((a, b) => a.localeCompare(b)).map((el) => ({text: el, value: collectionReduce[el]}));
    return state.collections.map((x) => ({
      text: `${x.itemGroupId} - ${x.code}`,
      value: x.id,
      itemGroupId: x.itemGroupId
    })).sort((a, b) => a.text.localeCompare(b.text))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
